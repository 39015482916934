const validateUser = (isNewUser) => (values) => {
    const errors = {};
    if (isNewUser && !values.password) {
        // You can return translation keys
        errors.password = 'Введите пароль';
    } else if (values.passwordConfirm !== values.password){
        errors.passwordConfirm = 'Пароль не совпадает с подтверждением пароля';
    }
    return errors
};

export const validateNewUserForm = validateUser(true);
export const validateEditUserForm = validateUser(false);