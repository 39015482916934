import * as React from "react";
import {Resource} from "react-admin";
import {APPLICATION_DOCUMENT_TYPES, MIME_TYPES, PAGE_NAMES, ROUTE_URLS, USER_ROLE} from "./api/constants";
import {setPageName} from "./api/utils";
import {EditUser} from "./routes/users/EditUser";
import {ListUsers} from "./routes/users/ListUsers";
import {CreateUser} from "./routes/users/CreateUser";
import {UsersIcon} from "./icons/iconsFactory";
import {CreateBlob} from "./routes/blobsUpload/CreateBlob";
import {ListBlobs} from "./routes/blobsUpload/ListBlobs";
import {
    AddPhotoAlternate as AddPhotoAlternateIcon,
    AspectRatio as AspectRatioIcon,
    Forum as ForumIcon,
    GridView as GridViewIcon,
    LibraryMusic as LibraryMusicIcon,
    PictureAsPdf as PictureAsPdfIcon,
    Route as RouteIcon,
    Settings as SettingsIcon,
    Videocam as CameraIcon,
    VideoLibrary as VideoLibraryIcon
} from '@mui/icons-material'
import {ListTrack} from "./routes/track/ListTrack";
import {ListFeedback} from "./routes/feedback/ListFeedback";
import {ShowFeedback} from "./routes/feedback/ShowFeedback";
import {Settings} from "./routes/settings/Settings";
import {ListTrip} from "./routes/trip/ListTrip";
import {ListCamera} from "./routes/camera/ListCamera";
import {CreateCamera} from "./routes/camera/CreateCamera";
import {EditCamera} from "./routes/camera/EditCamera";
import {Tiles} from "./routes/tiles/Tiles";
import pageNaming from "./pageNaming";
import {ApplicationDocument} from "./routes/applicationDocument/ApplicationDocument";
import {UploadInstruction} from "./routes/instruction/UploadInstruction";

export const routeManageUsers = () => [
    <Resource name={ROUTE_URLS.USERS}
              options={{label: PAGE_NAMES.USERS_LIST}}
              edit={props => {
                  setPageName(PAGE_NAMES.USER)
                  return EditUser({...props, role: USER_ROLE.USER})
              }}
              list={props => {
                  setPageName(PAGE_NAMES.USERS_LIST)
                  return ListUsers({...props, role: USER_ROLE.USER})
              }}
              create={props => {
                  setPageName(PAGE_NAMES.USER_CREATE)
                  return CreateUser({...props, role: USER_ROLE.USER})
              }}
              icon={UsersIcon}
    />,
    <Resource name={ROUTE_URLS.ADMINS}
              options={{label: PAGE_NAMES.ADMINS_LIST}}
              edit={props => {
                  setPageName(PAGE_NAMES.ADMIN)
                  return EditUser({...props, role: USER_ROLE.ADMIN})
              }}
              list={props => {
                  setPageName(PAGE_NAMES.ADMINS_LIST)
                  return ListUsers({...props, role: USER_ROLE.ADMIN})
              }}
              create={props => {
                  setPageName(PAGE_NAMES.ADMIN_CREATE)
                  return CreateUser({...props, role: USER_ROLE.ADMIN})
              }}
              icon={UsersIcon}
    />
];

export const routeCurrentUser = () => [
    <Resource name={ROUTE_URLS.USERS}
              edit={props => {
                  setPageName(PAGE_NAMES.USER)
                  return EditUser({...props})
              }}/>
];

export const routeManageVideos = () => [
    <Resource name={ROUTE_URLS.UPLOAD_VIDEO}
              options={{label: PAGE_NAMES.VIDEO}}
              list={props => {
                  setPageName(PAGE_NAMES.VIDEO)
                  const translations = {
                      pageTitleMessage: pageNaming.videos.list.header,
                      pageDescription: pageNaming.videos.list.description,
                      noItemsMessage: "Нет видеофайлов",
                      wouldYouLikeMessage: "Хотите загрузить новый видеофайл?"
                  };
                  return ListBlobs(props, translations)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.VIDEO);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.UPLOAD_VIDEO}
                                     description="Выберите видео в формате MP4. Размер файла не должен превышать 10Мб"
                                     accept={MIME_TYPES.VIDEO}/>
              }}
              icon={VideoLibraryIcon}/>
];

export const routeManageAudios = () => [
    <Resource name={ROUTE_URLS.UPLOAD_AUDIO}
              options={{label: PAGE_NAMES.AUDIO}}
              list={props => {
                  setPageName(PAGE_NAMES.AUDIO)
                  const translations = {
                      pageTitleMessage: pageNaming.audios.list.header,
                      pageDescription: pageNaming.audios.list.description,
                      noItemsMessage: "Нет аудиофайлов",
                      wouldYouLikeMessage: "Хотите загрузить новый аудиофайл?"
                  };
                  return ListBlobs(props, translations)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.AUDIO);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.UPLOAD_AUDIO}
                                     description="Выберите видео в формате MP3. Размер файла не должен превышать 10Мб"
                                     accept={MIME_TYPES.AUDIO}/>
              }}
              icon={LibraryMusicIcon}/>
];


export const routeManageImages = () => [
    <Resource name={ROUTE_URLS.UPLOAD_IMAGE}
              options={{label: PAGE_NAMES.IMAGE}}
              list={props => {
                  setPageName(PAGE_NAMES.IMAGE);
                  const translations = {
                      pageTitleMessage: pageNaming.images.list.header,
                      pageDescription: pageNaming.images.list.description,
                      noItemsMessage: "Нет изображений",
                      wouldYouLikeMessage: "Хотите загрузить новое изображение?"
                  };
                  return ListBlobs({imagePreview: true, ...props}, translations)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.IMAGE);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.UPLOAD_IMAGE}
                                     description="Выберите изображение в формате JPEG или PNG. Размер файла не должен превышать 10Мб"
                                     accept={MIME_TYPES.IMAGE}/>
              }}
              icon={AddPhotoAlternateIcon}/>
];

export const routeListFeedback = () => [
    <Resource name={ROUTE_URLS.FEEDBACK}
              options={{label: PAGE_NAMES.FEEDBACK}}
              list={props => {
                  setPageName(PAGE_NAMES.FEEDBACK);
                  return ListFeedback(props)
              }}
              show={props => {
                  setPageName(PAGE_NAMES.FEEDBACK);
                  return ShowFeedback(props)
              }}
              icon={ForumIcon}/>
];

export const routeManageInstructions = () => [
    <Resource name={ROUTE_URLS.UPLOAD_INSTRUCTIONS}
              options={{label: PAGE_NAMES.INSTRUCTIONS}}
              list={() => {
                  setPageName(PAGE_NAMES.INSTRUCTIONS);
                  return UploadInstruction()
              }}
              icon={PictureAsPdfIcon}/>
];

export const routeTrackFile = () => [
    <Resource name={ROUTE_URLS.TRACK}
              options={{label: PAGE_NAMES.TRACK}}
              list={props => {
                  setPageName(PAGE_NAMES.TRACK);
                  return ListTrack(props)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.TRACK);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.TRACK}
                                     description="Выберите файл маршрута в формате JSON. Размер файла не должен превышать 10 Мб"
                                     accept={'application/JSON'}/>
              }}
              icon={RouteIcon}/>
];

export const routeTripFile = () => [
    <Resource name={ROUTE_URLS.TRIP}
              options={{label: PAGE_NAMES.TRIP}}
              list={props => {
                  setPageName(PAGE_NAMES.TRACK);
                  return ListTrip(props)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.TRIP);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.TRIP}
                                     description="Выберите файл описания рейса в формате JSON"
                                     accept={'application/JSON'}/>
              }}
              icon={RouteIcon}/>
];

export const routeSettings = () => [
    <Resource name={ROUTE_URLS.SETTINGS}
              options={{label: PAGE_NAMES.SETTINGS}}
              list={() => {
                  setPageName(PAGE_NAMES.SETTINGS)
                  return <Settings/>
              }}
              icon={SettingsIcon}
    />
];

export const routePrivacyPolicy = () => [
    <Resource name={ROUTE_URLS.PRIVACY_POLICY}
              options={{label: PAGE_NAMES.PRIVACY_POLICY}}
              list={() => {
                  setPageName(PAGE_NAMES.PRIVACY_POLICY)
                  return ApplicationDocument({
                      apiUrl: ROUTE_URLS.PRIVACY_POLICY_API,
                      title: PAGE_NAMES.PRIVACY_POLICY,
                      applicationDocumentType: APPLICATION_DOCUMENT_TYPES.PRIVACY_POLICY
                  });
              }}
              icon={PictureAsPdfIcon}
    />,
]
export const routeTermOfUse = () => [
    <Resource name={ROUTE_URLS.TERM_OF_USE}
              options={{label: PAGE_NAMES.TERM_OF_USE}}
              list={() => {
                  setPageName(PAGE_NAMES.TERM_OF_USE)
                  return ApplicationDocument({
                      apiUrl: ROUTE_URLS.TERM_OF_USE_API,
                      title: PAGE_NAMES.TERM_OF_USE,
                      applicationDocumentType: APPLICATION_DOCUMENT_TYPES.TERMS_OF_USE
                  });
              }}
              icon={PictureAsPdfIcon}
    />,
]
export const routeTiles = () => [
    <Resource name={ROUTE_URLS.TILES}
              options={{label: PAGE_NAMES.TILES}}
              list={(props) => {
                  setPageName(PAGE_NAMES.TILES)
                  return Tiles({mimeType: MIME_TYPES.ARCHIVE, ...props})
              }}
              icon={GridViewIcon}
    />,
];

export const routeCameras = () => [
    <Resource name={ROUTE_URLS.CAMERAS}
              options={{label: PAGE_NAMES.CAMERAS_LIST}}
              list={props => {
                  setPageName(PAGE_NAMES.TRACK);
                  return ListCamera(props)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.CAMERA_CREATE);
                  return <CreateCamera {...props}
                                    redirectOnSuccess={ROUTE_URLS.CAMERAS}
                  />
              }}
              edit={props => {
                  setPageName(PAGE_NAMES.CAMERA)
                  return <EditCamera {...props}
                                  redirectOnSuccess={ROUTE_URLS.CAMERAS}
                  />
              }}
              icon={CameraIcon}
    />
];

export const routeManageLogo = () => [
    <Resource name={ROUTE_URLS.UPLOAD_LOGO}
              options={{label: PAGE_NAMES.LOGO}}
              list={props => {
                  setPageName(PAGE_NAMES.LOGO)
                  const translations = {
                      pageTitleMessage: pageNaming.logos.list.header,
                      pageDescription: pageNaming.logos.list.description,
                      noItemsMessage: "Нет логотипов",
                      wouldYouLikeMessage: "Хотите загрузить новый логотип?"
                  };
                  return ListBlobs({imagePreview: true, multipleRowsSelect: false, ...props}, translations)
              }}
              create={props => {
                  setPageName(PAGE_NAMES.LOGO);
                  return <CreateBlob {...props}
                                     redirectOnSuccess={ROUTE_URLS.UPLOAD_LOGO}
                                     description="Выберите изображение в формате PNG. Размер файла не должен превышать 10Мб"
                                     accept={MIME_TYPES.IMAGE}/>
              }}
              icon={AspectRatioIcon}/>
];
