import * as React from "react";
import {Create, useNotify,} from 'react-admin';
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import CameraForm from "./CameraForm";

export const CreateCamera = props => {

    const notify = useNotify();

    const onFailure = (error) => {
        notify(error.message, {type: 'warning'});
    };

    return <Box>
        <PageTitle header={pageNaming.cameras.create.header}
                   description={pageNaming.cameras.create.description}
        />
        <Create {...props} onFailure={onFailure} mutationMode="pessimistic">
            <CameraForm isNew={true}/>
        </Create>
    </Box>
}