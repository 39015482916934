import {Box} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";

const useCardStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        backgroundColor: '#FFF',
        borderRadius: '10px',
        padding: '10px',
    }
})

export const Card = (props) => {
    const classes = useCardStyles()
    return <Box {...props} className={classes.container}></Box>
}
