import {makeStyles} from "@material-ui/core/styles";

export const buttonStyleOverrideSx = {
    backgroundColor: '#1a2936',
    '&&:hover': {
        backgroundColor: 'rgb(18, 28, 37)'
    }
}

export const useAuthPagesStyles = makeStyles({
    body: {
        height: '1px',
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundSize: 'cover',
        justifyContent: 'flex-start',
        backgroundColor: ' #253b4d',
        backgroundImage: 'none',
        backgroundRepeat: 'no-repeat',

    },
    login: {
        backgroundColor: 'white',
        borderRadius: '4px',
        marginTop: '6rem',
        overflow: 'hidden',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),' +
            '0px 1px 1px 0px rgba(0,0,0,0.14),' +
            '0px 1px 3px 0px rgba(0,0,0,0.12)',
        minWidth: '300px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '0.5rem',
    },
    button: {
        color: 'fff',
        backgroundColor: '#1a2936',
        width: '100%',
    },
    textFieldGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '0.5rem',
    },
    icon: {
        marginRight: "0.5rem"
    }
})