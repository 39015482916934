import * as React from "react";
import {Datagrid, DateField, FunctionField, List, TextField} from 'react-admin';
import {BissPagination} from "../../components/Pagination";
import {Box, Typography} from '@mui/material';
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {FEEDBACK_MARKS} from "../../api/constants";

export const ListFeedback = props => {
    return <Box>
        <PageTitle header={pageNaming.feedback.list.header}
                   description={pageNaming.feedback.list.description}
        />
        <List {...props}
              title={pageNaming.feedback.list.header}
              bulkActionButtons={false}
              exporter={false}
              pagination={<BissPagination/>}
              empty={<EmptyPage header='Нет сообщений обратной связи'/>}
        >
            <Datagrid rowClick="show">
                <DateField source="createdAt" label="Дата добавления" showTime locales={'ru'}/>
                <TextField source={'name'}
                           label={'Имя'}
                           sortable={false}
                />
                <FunctionField label={"Предпросмотр сообщения"}
                               render={record => {
                                   const messagePreview = record.message.substring(0, 100)
                                   return <Typography variant={'caption'}>
                                       {messagePreview}{messagePreview.length <= 100 ? "" : "..."}
                                   </Typography>
                               }}/>
                <FunctionField label={"Оценка"}
                               render={record => {
                                   return <Typography variant={'caption'}>
                                       {FEEDBACK_MARKS[record.feedbackMark]}
                                   </Typography>
                               }}/>

            </Datagrid>
        </List>
    </Box>;
}
