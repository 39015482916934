import {Datagrid, DateField, List, TextField} from "react-admin";
import * as React from "react";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import EmptyPage from "../../components/EmptyPage";

export const ListTrip = ({...props}) => {
    return <Box>
        <PageTitle header={pageNaming.trip.list.header} description={pageNaming.trip.list.description}/>
        <List {...props}
              exporter={false}
              bulkActionButtons={false}
              empty={<EmptyPage header={'Нет рейсов'}/>}
        >
            <Datagrid>
                <TextField source={'id'} label={'ID'}/>
                <TextField source={'name'} label={'Название'}/>
                <TextField source={'trackId'} label={'Track ID'}/>
                <DateField source={'departureDate'} label={'Время отправления'} showTime />
                <DateField source={'arrivalDate'} label={'Время прибытия'} showTime />
            </Datagrid>
        </List>
    </Box>
}
