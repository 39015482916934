import {
    maxLength,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {Box} from "@material-ui/core";
import React from "react";
import {useFormStyles} from "./formStyles";

const CameraForm = ({isNew, record, ...restProps}) => {
    const classes = useFormStyles();

    return (
        <SimpleForm {...restProps}
                    redirect="list"
        >
            <Box id="columnsContainer" className={classes.columns}>
                <Box className={classes.content}>
                    <Box className={classes.container}>
                        <TextInput source="title"
                                   options={{autoComplete: 'none'}}
                                   label="Заголовок"
                                   validate={[maxLength(256), required()]}
                                   className={classes.input}
                        />
                    </Box>
                    <Box className={classes.container}>
                        <TextInput source="url"
                                   options={{autoComplete: 'none'}}
                                   label="URL"
                                   validate={[maxLength(256), required()]}
                                   className={classes.input}
                        />
                    </Box>
                    <Box className={classes.container}>
                        <TextInput source="description"
                                   options={{autoComplete: 'none'}}
                                   label="Описание"
                                   multiline
                                   className={classes.input}
                        />
                    </Box>
                </Box>
            </Box>
        </SimpleForm>
    )
}

export default CameraForm;