import {validateEditUserForm, validateNewUserForm} from "../../validation/validateUserDetails";
import {BooleanInput, maxLength, minLength, PasswordInput, required, SimpleForm, TextInput,} from 'react-admin';
import {useFormStyles} from "../../themes/formStyles";
import {USER_ROLE} from "../../api/constants";
import {Box, IconButton, InputAdornment} from "@material-ui/core";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const UserDetailsForm = ({isNew, role, permissions, record, isCurrentUser, ...restProps}) => {
    const classes = useFormStyles();
    const [hasConfirm, setHasConfirm] = useState(true)

    return (
        <SimpleForm {...restProps}
                    redirect="list"
                    validate={(record.role === USER_ROLE.ADMIN) && hasConfirm
                        ? isNew
                            ? validateNewUserForm
                            : validateEditUserForm
                        : ''
                    }
        >
            <Box id="columnsContainer" className={classes.columns}>
                <Box className={classes.content}>
                    <Box className={classes.container}>
                        <TextInput source="name"
                                   options={{autoComplete: 'none'}}
                                   label={role === USER_ROLE.USER ? "Имя" : "Фамилия Имя Отчество"}
                                   disabled={!permissions?.isAdmin}
                                   validate={[maxLength(256), required()]}
                                   className={classes.userName}
                        />
                    </Box>
                    {role === USER_ROLE.ADMIN && <Box className={classes.container}>
                        <TextInput source="login"
                                   options={{autoComplete: 'none'}}
                                   label="Логин"
                                   disabled={!permissions?.isAdmin}
                                   validate={[maxLength(20), required()]}
                                   className={classes.userLogin}
                        />
                    </Box>}

                    {!isCurrentUser && <Box className={classes.container}>
                        <BooleanInput label="Заблокирована" source="isDisabled"/>
                    </Box>}
                    {role === USER_ROLE.USER && <Box>
                        <Box className={classes.container}>
                            <TextInput source={'passportNumber'}
                                       options={{autoComplete: 'none'}}
                                       label="4 последние цифры документа"
                                       validate={[maxLength(4), minLength(4), required()]}
                                       className={classes.userLogin}/>
                        </Box>
                        <Box className={classes.container}>
                            <TextInput source={'cabinNumber'}
                                       options={{autoComplete: 'none'}}
                                       label="Номер каюты"
                                       validate={[maxLength(20), required()]}
                                       className={classes.userLogin}/>
                        </Box>
                    </Box>}

                    {role === USER_ROLE.ADMIN && <Box>
                        <Box className={classes.container}>
                            <TextInput source="password"
                                       options={{autoComplete: 'none'}}
                                       label="Пароль"
                                       className={classes.userPassword}
                                       type={!hasConfirm ? 'text' : 'password'}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton onClick={() => setHasConfirm(!hasConfirm)}>
                                                       {!hasConfirm ? <Visibility/> : <VisibilityOff/>}
                                                   </IconButton>
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        </Box>
                        {hasConfirm && <Box className={classes.container}>
                            <PasswordInput source="passwordConfirm"
                                           options={{autoComplete: 'none'}}
                                           label="Подтверждение пароля"
                                           className={classes.userPassword}
                            />
                        </Box>}
                    </Box>}
                </Box>
            </Box>
        </SimpleForm>
    )
}

export default UserDetailsForm;
