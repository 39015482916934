import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {FileUploadForm} from "../../components/fileUpload/FileUploadForm";
import {HTTP_METHOD, MIME_TYPES, ROUTE_URLS} from "../../api/constants";
import * as React from "react";
import {useEffect, useState} from "react";
import {useVersion} from "react-admin";
import {base64toBlob, downloadFile} from "../../api/utils";
import {Link} from "@material-ui/core";
import {fetch} from "../../api/httpClient";

export const UploadInstruction = () => {
    const [currentDocument, setCurrentDocument] = useState(null);
    const version = useVersion();

    const reloadCurrentPrivacyPolicy = () => {
        const url = `/${ROUTE_URLS.INSTRUCTIONS_API_METADATA}`;
        fetch(url, {
            method: HTTP_METHOD.GET,
        })
            .then((request) => {
                setCurrentDocument(request.data);
            });
    }

    useEffect(() => {
        reloadCurrentPrivacyPolicy();
    }, [version]);

    return <Box>
        <PageTitle header={pageNaming.help.list.header}
                   description={pageNaming.help.list.description}/>
        <FileUploadForm accept={MIME_TYPES.PDF}
                        sendWithBase64={true}
                        resource={ROUTE_URLS.UPLOAD_INSTRUCTIONS}
                        label={''}
                        aside={currentDocument &&
                            <Link onClick={() =>
                                downloadFile(base64toBlob(currentDocument.base64File), currentDocument.fileName)}
                                  style={{cursor: 'pointer'}}
                            >
                                Текущий документ: {currentDocument.fileName}
                            </Link>}
        />
    </Box>
}
