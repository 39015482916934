import {makeStyles} from "@material-ui/core/styles";

export const useImageSlidesStyles = makeStyles({
    hr: {
        width: '100%',
        borderTop: '1px',
    },
    certificate: {
        color: '#7E7E7E'
    }
})
