import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Confirm, Datagrid, DateField, FunctionField, List, TextField, useNotify, useRefresh} from 'react-admin';
import {BissPagination} from "../../components/Pagination";
import {useTableStyles} from "../../themes/tableStyles";
import {Box} from '@mui/material';
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';
import {deleteBlob} from "../../api/blob";
import {ROUTE_URLS} from "../../api/constants";
import {fetch} from "../../api/httpClient";
import {useStyles} from "./styles";


const ImagePreview = ({record}) => {
    const classes = useStyles();

    const [image, setImage] = useState('')
    useEffect(() => {
        fetch(`/${ROUTE_URLS.UPLOAD_LOGO}/${record.id}`)
            .then(({data}) => setImage(data.base64File))
    }, [])

    return <Box className={classes.imageContainer}>
        <img src={image} alt={'Ошибка загрузки'} className={classes.image}/>
    </Box>
}

export const ListBlobs = ({multipleRowsSelect = true, ...props}, translations) => {
    const tableStyles = useTableStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const {pageTitleMessage, noItemsMessage, wouldYouLikeMessage, pageDescription} = translations;
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const renderButtons = (record) =>
         <Button title="Удалить" onClick={() => {
             setItemIdToDelete(record.id);
         }}>
             <DeleteIcon/>
         </Button>;
    const deleteBlobCallback = (id) => {
        deleteBlob(id)
            .then(() => {
                refresh();
                notify('Запись успешно удалена');
                setItemIdToDelete(null);
            }
        );
    }
    return <Box>
        <PageTitle header={pageTitleMessage}
                   description={pageDescription}
        />
        <Confirm
            isOpen={itemIdToDelete}
            title={`Удалить запись`}
            content={<>
                <p>Вы уверены что хотите удалить запись</p>
                <i>{itemIdToDelete} ?</i>
            </>}
            onConfirm={() => deleteBlobCallback(itemIdToDelete)}
            onClose={() => setItemIdToDelete(null)}
        />
        <List {...props}
              title={pageTitleMessage}
              bulkActionButtons={multipleRowsSelect}
              exporter={false}
              pagination={multipleRowsSelect && <BissPagination/>}
              empty={<EmptyPage header={noItemsMessage}
                                description={wouldYouLikeMessage}/>}
        >
            <Datagrid classes={{headerCell: tableStyles.thead}}>
                <TextField source="id" label="ID"/>
                <TextField source="fileName" label="Имя файла"/>
                <DateField source="createdAt" label="Дата загрузки" locales={'ru'} showTime/>
                {props.imagePreview
                    ? <FunctionField label="Предпросмотр изображения"
                                     render={record => <ImagePreview record={record}/>}
                    />
                    : null
                }
                <FunctionField label="Действия" render={renderButtons} />
            </Datagrid>
        </List>
    </Box>;
}
