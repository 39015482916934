import {makeStyles} from "@material-ui/core/styles";

export const useRouteInfoStyles = makeStyles(() => {

    return {
        connector: {
            borderLeft: 'solid black 1px',
            minHeight: '70px',
            height: '100%',
            width: '0',
            marginTop: '-15px',
            marginBottom: '-15px',
            marginLeft: '11px'
        },
        stepContainer: {
            position: 'relative'
        },
        stepIcon: {
            height: '25px',
            width: '25px',
            zIndex: 2,
            position: 'absolute',
            top: '7px',
            left: '-20px',
        },
        line: {
            position: "absolute",
            height: ({lineHeight}) => lineHeight,
            zIndex: 0,
            borderLeft: 'solid 1px #2161E3',
            top: '13px',
            left: '12px'
        },
        title: {
            color: '#7E7E7E',
        },
        titleBox: {
            marginLeft: '9px',
            marginBottom: '6px'
        },
        contentBox: {
            position: 'relative'
        }
    }
})

export const useRouteStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    city: {
        fontWeight: '800!important'
    },
    date: {
        fontWeight: 800,
        color: '#2161E3'
    },
    arrivalTime: {
        fontWeight: 600
    },
    departureTime: {
        fontWeight: 600
    },
    timeRow: {
        marginBottom: '6px'
    }
})

export const stepLabelStyles = makeStyles({
    root: {
        display: "-webkit-flex",
        alignItems: "start",
        position: 'relative',
        marginLeft: '20px'
    }
})
