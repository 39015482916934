import * as React from "react";
import {useState} from "react";
import {
    Button,
    CreateButton,
    Datagrid,
    FunctionField,
    List,
    SearchInput,
    TextField,
    TopToolbar,
    useNotify,
    useRefresh
} from 'react-admin';
import {BissPagination} from "../../components/Pagination";
import {useTableStyles} from "../../themes/tableStyles";
import {Box} from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import {HTTP_METHOD, ROUTE_URLS, USER_ROLE} from "../../api/constants";
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {Block as BlockIcon, Check as CheckIcon, DeleteSweep as DeleteSweepIcon,} from '@mui/icons-material'
import {Modal} from "../../components/Modal";
import {fetch} from "../../api/httpClient";

export const ListUsers = props => {
    const tableStyles = useTableStyles();
    const notify = useNotify()
    const refresh = useRefresh()
    const [openModal, setOpenModal] = useState(false)

    const deleteAll = () => {
        fetch(`/${ROUTE_URLS.USERS}/delete-all`, {method: HTTP_METHOD.DELETE})
            .then(({data: {result}}) => {
                notify(result)
                refresh()
            })
    }

    const filters = [
        <SearchInput
            source="name"
            alwaysOn
        />
    ];

    const usersListStyles = makeStyles({
        dashboardsContainer: {
            display: "flex",
        },
        dashboardChip: {
            margin: '2px'
        }
    })();

    return <Box>
        <PageTitle header={(props.role === USER_ROLE.USER ? pageNaming.users : pageNaming.admins).list.header}
                   description={(props.role === USER_ROLE.USER ? pageNaming.users : pageNaming.admins).list.description}
        />
        <List {...props}
              title={props.role === USER_ROLE.USER ? 'Список пользователей' : 'Список администрторов'}
              bulkActionButtons={false}
              exporter={false}
              pagination={<BissPagination/>}
              empty={<EmptyPage header={props.role === USER_ROLE.USER
                  ? 'Нет пользователей'
                  : 'Нет администраторов'}
                                description={props.role === USER_ROLE.USER
                                    ? 'Хотите добавить нового пользователя?'
                                    : 'Хотите добавить нового администратора?'}/>}
              filters={filters}
              actions={<TopToolbar>
                  {props.role === USER_ROLE.USER && <>
                      <Button label="Очистить список пользователей"
                              onClick={() => setOpenModal(true)}
                      >
                          <DeleteSweepIcon/>
                      </Button>
                      <Modal open={openModal}
                             onClose={() => {
                                 setOpenModal(false)
                             }}
                             title={'Вы уверены, что хотите очистить список пользователей?'}
                             actions={<Box>
                                 <Button label={'Подтвердить'}
                                         onClick={() => {
                                             deleteAll()
                                             setOpenModal(false)
                                         }}
                                 >
                                     <CheckIcon/>
                                 </Button>
                                 <Button label={"Отменить"}
                                         onClick={() => setOpenModal(false)}
                                 >
                                     <BlockIcon/>
                                 </Button>
                             </Box>}
                      />
                  </>}
                  <CreateButton/>
              </TopToolbar>}
        >
            <Datagrid rowClick="edit" classes={{headerCell: tableStyles.thead}} style={{tableLayout: 'fixed'}}>
                <TextField source="name" label="Имя"/>
                {props.role === USER_ROLE.ADMIN && <TextField source="login" label="Логин"/>}
                {props.role === USER_ROLE.USER
                    && <TextField source="passportNumber" label="4 последние цифры документа"/>}
                {props.role === USER_ROLE.USER
                    && <TextField source="cabinNumber" label="Номер каюты"/>}

                <FunctionField label="Статус"
                               render={record => <Box className={usersListStyles.dashboardsContainer}>
                                   {record?.isDisabled
                                       ? 'Заблокирована'
                                       : 'Активна'}
                               </Box>}/>
            </Datagrid>
        </List>
    </Box>;
}
