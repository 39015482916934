import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {useMapStyles} from "./styles";
import {POSITION_UPDATE_FREQUENCY_MS} from '../../settings/appsettings';
import {fetchTrackConfig} from '../../api/tracks';
import {MapSettings, StopPoint, Track} from '../../api/tracks.d';
import * as olProj from 'ol/proj';
import {FeatureType, getMapZoomMode, transformCoordinatesForPoint} from './features/common';
import {fetchCurrentPosition} from '../../api/currentPosition';
import {getCurrentPosStyle, getCurrentPosUnknownStyle} from './features/styles';
import {CurrentPosition} from '../../api/currentPosition.d';
import {drawMap, getPoiLayer, getStopLayer} from "./features/map";
import {NavigationMode, NavModeButton} from "./components/NavButton/NavModeButton";
import {ErrorMessage} from "./components/ErrorMessage/ErrorMessage";
import {MAP_PARAM_FOR_MOBILE, MAP_PARAM_SCREEN_HEIGHT, MAP_PARAM_SCREEN_WIDTH} from "../../api/constants";
import {useLocation} from 'react-router-dom';
import {MapBrowserEvent} from "ol";
import {StopDescriptionPopup} from "./components/StopDescription/StopDescriptionPopup";
import {calculateZoomLevelForResolution} from "./utils";

enum MapZoomMode {
    MAP_FRAGMENT = 'MAP_FRAGMENT',
    FULL_MAP = 'FULL_MAP'
}

export const MapPage = props => {
    const [navigationMode, setNavigationMode] = useState<NavigationMode>(NavigationMode.Follow);
    const [map, setMap] = useState(null);
    const [error, setError] = useState<string>(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [currentPositionFeature, setCurrentPositionFeature] = useState(null);
    const [trackSettings, setTrackSettings] = useState<MapSettings | null>(null);
    const [selectedStopFeature, setSelectedStopFeature] = useState<StopPoint>()
    const [open, setOpen] = useState<boolean>(false)
    function listener (evt: MapBrowserEvent<any>) {
        const feature = map.forEachFeatureAtPixel(evt.pixel, function (feat: any, layer: any) {
                return feat;
            }
        );

        if (feature && feature.get('type') === FeatureType.STOP) {
            const poiDetails: StopPoint = {
                coordinate: feature.get('coordinate'),
                title: feature.get('title'),
                description: feature.get('description'),
                arrivalDate: feature.get("arrivalDate"),
                departureDate: feature.get("departureDate")
            };
            setSelectedStopFeature(poiDetails);
            setOpen(true);
        }
    }

    const mapRef = useRef();
    const location = useLocation();
    const classes = useMapStyles();
    /**
     * Загрузить файл с настройками трека и нарисовать карту. Вызывается при первой загрузке страницы
     */
    const loadTrack = async () => {
        try {
            const trackResponse = await fetchTrackConfig();
            const track: Track = await trackResponse.data;

            const mapDescriptor = drawMap(track, mapRef);
            setTrackSettings(track.settings);
            setMap(mapDescriptor.map);
            setCurrentPositionFeature(mapDescriptor.currentPositionFeature);
        } catch (error) {
            console.log(error.message);
            setError(error.message);
        }
    };

    /**
     * Загрузить текущее положение корабля через json и сохранить его в react state
     */
    const loadCurrentPosition = () => {

        fetchCurrentPosition().then((response) => {
            const position: CurrentPosition = response.data;
            setCurrentPosition(position);
            setError(null);
        })
            .catch((error) => {
                const errorMessage = error.response?.data?.message ? error.response?.data?.message
                    : `Ошибка при попытке получить текущее местоположение\n ${error.message}; URL: ${error.config.url}`;
                setCurrentPosition(null);
                setError(errorMessage);
            });
    }

    const getCenter = (zoomMode: MapZoomMode) => {
        const centerCoords = zoomMode === MapZoomMode.FULL_MAP
            ? {
                latitude: (trackSettings.mapNorthEastPoint.latitude + trackSettings.mapSouthWestPoint.latitude) / 2,
                longitude: (trackSettings.mapNorthEastPoint.longitude + trackSettings.mapSouthWestPoint.longitude) / 2
            }
            : trackSettings.mapInitialPosition;
        return olProj.fromLonLat([centerCoords.longitude, centerCoords.latitude]); //ширина и долгота тут перепутаны местами!
    }

    useEffect(() => {
        if (trackSettings && map) {
            map.addEventListener('click', listener);
            const zoomMode = getMapZoomMode();
            const previousZoomLevel = map?.getView().getZoom();
            const newZoomLevel = zoomMode === MapZoomMode.FULL_MAP
                ? calculateZoomLevelForResolution({
                bounds: {
                    mapNorthEastPoint: trackSettings.mapNorthEastPoint,
                    mapSouthWestPoint: trackSettings.mapSouthWestPoint
                },
                screenResolution: {
                    width: Number(new URLSearchParams(location.search).get(MAP_PARAM_SCREEN_WIDTH)),
                    height: Number(new URLSearchParams(location.search).get(MAP_PARAM_SCREEN_HEIGHT))
                },
                availableZoomLevels: trackSettings.availableZoomLevels
            }) || trackSettings?.fullRouteZoomLevel
                : trackSettings?.fragmentZoomLevel;
            if (map && newZoomLevel && previousZoomLevel !== newZoomLevel) {
                setNavigationMode(zoomMode === MapZoomMode.FULL_MAP ? NavigationMode.Free : NavigationMode.Follow);
                map.getView().setMaxZoom(newZoomLevel)
                map.getView().setMinZoom(newZoomLevel);
                map.getView().setZoom(newZoomLevel);
                map.getView().setCenter(getCenter(zoomMode));
                getPoiLayer(map).setVisible(zoomMode === MapZoomMode.MAP_FRAGMENT);
                getStopLayer(map).setVisible(zoomMode === MapZoomMode.FULL_MAP);
            }
        }
    }, [location.search, trackSettings, map]);

    useEffect(() => {
        loadTrack();
        //isForMobile будет true если пользователь пытается открыть карту с мобильного устройства
        const params = new URLSearchParams(window.location.hash.substr('#map?'.length + 1));
        let isForMobile = params.get(MAP_PARAM_FOR_MOBILE) && params.get(MAP_PARAM_FOR_MOBILE).toLowerCase() === 'true';

        console.log(`is for mobile: ${isForMobile}`);

        let interval = setInterval(() => {
            loadCurrentPosition();
        }, POSITION_UPDATE_FREQUENCY_MS);
        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        //Этот код вызываетсяпри изменении state для Current Position. Он перерисовывает новое положение корабля
        //и скорллит карту, если в этом есть необходимость
        if (!currentPositionFeature || !map) {
            return;
        }
        if(!currentPosition){
            //ошибка при загрузке текущего положения
            currentPositionFeature.setStyle(getCurrentPosUnknownStyle());
            return;
        }
        const newCenter = olProj.fromLonLat([currentPosition.longitude, currentPosition.latitude]);
        currentPositionFeature.getGeometry().setCoordinates(transformCoordinatesForPoint(currentPosition));
        currentPositionFeature.setStyle(getCurrentPosStyle(currentPosition.azimuth));
        if(navigationMode == NavigationMode.Follow) {
            map.getView().setCenter(newCenter);
        }
    }, [currentPosition, currentPositionFeature, map]);

    const onNavigationModeClick = () => {
        if(navigationMode === NavigationMode.Follow ){
            setNavigationMode(NavigationMode.Free);
        }else {
            setNavigationMode(NavigationMode.Follow);
            map.getView().setCenter(currentPositionFeature.getGeometry().getCoordinates());
        }
    }

    return (
        <>
            <NavModeButton onNavigationModeClick={onNavigationModeClick} navigationMode={navigationMode} />
            <div ref={mapRef} className={classes.map}></div>
            <ErrorMessage message={error}/>
            <StopDescriptionPopup selectedStopFeature={selectedStopFeature}
                                  open={open}
                                  onClose={() => setOpen(false)}
            />
        </>
    );
}
