import React from "react";
import {Layout, Sidebar} from "react-admin";
import BissAppBar from "./BissAppBar";
import BissMenu from "./BissMenu";

const BissSidebar = props => {
    return (
        <Sidebar {...props} />
    );
};

const BissLayout = props =>
    <Layout {...props} sidebar={BissSidebar} appBar={BissAppBar}
            menu={BissMenu}
    />;
export default BissLayout;