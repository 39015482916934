import {useState} from "react";
import {TextField} from "@mui/material";

export const TextInput = ({onChange, helperText = 'Обязательно для заполнения', required, ...props}) => {
    const [value, setValue] = useState('')
    const [blur, setBlur] = useState(false)

    return <TextField error={!!required && blur && value === ''}
                      helperText={!!required && blur && value === '' && helperText}
                      {...props}
                      onBlur={() => setBlur(true)}
                      onChange={e => {
                          setValue(e.target.value)
                          onChange(e)
                      }}
    />
}