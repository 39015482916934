import {TrackPoint} from "../../../api/tracks.d";
import * as olProj from "ol/proj";
import {MAP_PARAM_ZOOM_MODE} from "../../../api/constants";

export const transformCoordinatesForPoint = (trackPoint: TrackPoint) =>
    olProj.transform([trackPoint.longitude, trackPoint.latitude], 'EPSG:4326', 'EPSG:3857');

export enum MapZoomMode {
    MAP_FRAGMENT = 'MAP_FRAGMENT',
    FULL_MAP = 'FULL_MAP'
}

export enum FeatureType {
    POI = 'POI',
    STOP = 'STOP'
}

export function getMapZoomMode():MapZoomMode {
    return new URLSearchParams(window.location.hash.substr('#map?'.length + 1)).get(MAP_PARAM_ZOOM_MODE) as MapZoomMode;
}
