import {Box, Stack, Typography} from "@mui/material";
import {useRouteStyles} from "./routeInfoStyles";

export const Route = ({name, arrivalDate, departureDate, activities, description}) => {
    const classes = useRouteStyles();
    const renderTextWithLineBreaks = (text) => text.split('\n').map((line, index) => {
        return line === ''
            ? <br/>
            : <p key={index}>{line}</p>;
    });

    const getTime = (dateString) => {
        const date = new Date(dateString);

        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            weekday: 'short'
        };

        return  date.toLocaleTimeString('ru-RU', timeOptions);
    }

    const getDate = (dateString) => {
        const date = new Date(dateString);

        const options = {
            day: '2-digit',
            month: 'short',
            weekday: 'short'
        };

        return  date.toLocaleDateString('ru-RU', options);
    }

    return <Stack gap={1}>
        <Stack direction={'row'} gap={2}>
            <Box flexGrow={0}>
                <Box>
                    <Typography className={classes.date} component={'span'}>{getDate(arrivalDate || departureDate)} </Typography>
                </Box>

                {arrivalDate && <Box className={classes.timeRow}>
                    <Typography className={classes.arrivalTime} component={'span'}>{getTime(arrivalDate)} </Typography>
                </Box>}

                {departureDate && <Box className={classes.timeRow}>
                    <Typography className={classes.departureTime} component={'span'}>{getTime(departureDate)} </Typography>
                </Box>}
            </Box>
            <Box flexGrow={1}>
                <Box>
                    <Typography className={classes.city} component={'span'}>{name}</Typography>
                </Box>

                {arrivalDate && <Box className={classes.timeRow}>
                    <Typography component={'span'}>время прибытия</Typography>
                </Box>}

                {departureDate && <Box className={classes.timeRow}>
                    <Typography component={'span'}>время отправления</Typography>
                </Box>}
            </Box>
        </Stack>
        {(activities?.length || description) && <Box>
                {description && <Typography>
                    <b>
                        {description}
                    </b>
                </Typography>}
                {(activities || []).map(activity => <Typography>
                    {renderTextWithLineBreaks(activity.title)}
                </Typography>)}
            </Box>}
    </Stack>
}
