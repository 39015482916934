import {Dialog, DialogContent, DialogContentText, Typography} from "@mui/material";
import {CircularProgress} from "@material-ui/core";
import * as React from "react";
import {useFileUploadFormStyles} from "./fileUploadStyles";

export const FileUploadingDialog = ({open}) => {
    const classes = useFileUploadFormStyles()
    return (
        <Dialog open={open}>
            <DialogContent>
                <DialogContentText className={classes.loadingContainerDialog}>
                    <Typography>Идет загрузка файла</Typography>
                    <CircularProgress size={20} thickness={3}/>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
