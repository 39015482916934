import {AUTH_RESPONSE, LOCAL_STORAGE_AUTH, USER_ROLE} from '../api/constants';
import {login} from '../api/auth';
import messages from "../messages";

export function parseJwt(token) {
    if (!token) {
        return false;
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const getUserRole = () => parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userRole;
const authProvider = {
    login: ({username, password}) => {
        const loginRequest = login(username, password);

        return loginRequest
            .then(response => {
                //успешно залогинились в основное приложение. Устанавливаем json webtoken
                localStorage.setItem(LOCAL_STORAGE_AUTH, response.headers['authorization']);
                return Promise.resolve();
            })
            .catch((e) => {
                if (e.response?.data?.messageCode === AUTH_RESPONSE.BAD_CREDENTIALS) {
                    return Promise.reject(e.response?.data?.message);
                } else if (e.response?.data?.messageCode && messages.error[e.response?.data?.messageCode]) {
                    return Promise.reject(messages.error[e.response?.data?.messageCode]);
                } else {
                    return Promise.reject(messages.error.applicationUnavailable);
                }
            });
    },

    logout: () => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH);
        return Promise.resolve();
    },

    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem(LOCAL_STORAGE_AUTH);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getCurrentUserId: () => {
        return parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userId;
    },
    getCurrentUserName: () => {
        return parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userFullName;
    },
    getCurrentUserRole: () => {
        return getUserRole()
    },
    checkAuth: () => {
        if (localStorage.getItem(LOCAL_STORAGE_AUTH)) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    },
    isAdmin: () => {
        return getUserRole() === USER_ROLE.ADMIN
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve({
        isAdmin: getUserRole() === USER_ROLE.ADMIN,
    }),
};

export default authProvider;