import {AppBar, MenuItemLink, useLoading, useRefresh, UserMenu} from "react-admin";
import auth from "../../auth/authProvider";
import authProvider from "../../auth/authProvider";
import {Box, Link} from '@material-ui/core';
import {useAppBarStyles} from "./appBarStyles";
import {RefreshIcon, UserIcon} from "../../icons/iconsFactory";
import {ROUTE_URLS} from "../../api/constants";
import {IconButton} from "@mui/material";

const RefreshButton = () => {
    const refresh = useRefresh();
    return <IconButton onClick={() => refresh()} color="inherit">
        <RefreshIcon/>
    </IconButton>;
};

const BissAppBar = (props) => {
    const classes = useAppBarStyles();
    const isLoading = useLoading()

    const BissUserMenu = (props) => {
        return <UserMenu icon={<UserIcon/>} {...props}>
            <MenuItemLink
                to={`/${ROUTE_URLS.ADMINS}/${auth.getCurrentUserId()}`}
                primaryText="Мой Профиль"
            />
        </UserMenu>;
    };

    return <AppBar {...props} userMenu={<BissUserMenu/>}>
        <Link href={ROUTE_URLS.HOME} className={classes.image} children={""}/>
        <Link href={ROUTE_URLS.HOME} className={classes.caption} underline="none" variant='h6' color="inherit" children={""}/>
        <Box>{authProvider.getCurrentUserName()}</Box>
        {!isLoading && <RefreshButton/>}
    </AppBar>
};

export default BissAppBar;
