import {Box} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Typography} from "@material-ui/core";
import {DoNotDisturbOn as DoNotDisturbOnIcon} from '@mui/icons-material'
import {useVersion} from "react-admin";
import {useFileInputStyles} from "./fileInputStyles";

export const FileInput = ({onChange, multiple = false, label, accept}) => {
    const classes = useFileInputStyles()
    const [files, setFiles] = useState([])
    const version = useVersion()
    useEffect(() => {
        onChange(files)
    }, [files])

    useEffect(() => {
        setFiles([])
    }, [version])

    const handleOnDrop = useCallback(acceptedFiles => {
        multiple
            ? setFiles(prevState => {
                const result = [...prevState]
                acceptedFiles.forEach(acceptedFile => {
                    let isDuplicate = false
                    files.forEach(file => {
                        const isFileSame = file.name === acceptedFile.name
                            && file.path === acceptedFile.path
                            && file.size === acceptedFile.size
                            && file.lastModified === acceptedFile.lastModified
                            && file.type === acceptedFile.type
                        if (isFileSame) {
                            isDuplicate = true;
                        }
                    })
                    if (!isDuplicate) {
                        result.push(acceptedFile)
                    }
                });
                return result
            })
            : setFiles(acceptedFiles)
    }, [files])

    const getAcceptedFileTypes = () => {
        const result = {};
        accept
            .split(",")
            .map(format => format.trim())
            .forEach(format => {
                result[format] = [];
            });
        return result
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        accept: getAcceptedFileTypes(),
        maxFiles: multiple ? 0 : 1,
        onDrop: (props) => handleOnDrop(props)
    });

    const removeFile = file => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles)
    }

    const filesRender = () => <Box className={classes.acceptedFilesContainer}>
        {files.map((file, idx) => (
            <Box key={idx} className={classes.acceptedFile}>
                <Box className={classes.deleteFileIconBorder}
                     onClick={removeFile(file)}
                >
                    <DoNotDisturbOnIcon className={classes.deleteFileIcon}/>
                </Box>
                <Typography>
                    {file.name}
                </Typography>
            </Box>
        ))}
    </Box>

    return <Box>
        <Typography className={classes.labelText}
                    component={'span'}
        >
            {label}
        </Typography>
        <Box {...getRootProps({className: isDragActive ? classes.containerIsDragActive : classes.container})}>
            <Box className={classes.scontainer}>
                <input {...getInputProps()} />
                <Typography className={classes.placeholder}>
                    {isDragActive ? "Перетащите файл сюда" : "Перетащите файл сюда или нажмите для выбора."}
                </Typography>
            </Box>
        </Box>
        {files.length > 0 && filesRender()}
    </Box>
}
