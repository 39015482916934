import {anonymousQuery, getServiceHost} from './httpClient';
import {ROUTE_URLS} from "./constants";

export const login = (login, password) => {
    const url = getServiceHost(ROUTE_URLS.LOGIN) + ROUTE_URLS.LOGIN;
    return anonymousQuery.post(url,
        {
            'login': login,
            'password': password
        }
    );
}
