import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import {FeatureType} from "./common";

export const trackStyle = new Style({
    stroke: new Stroke({
        color: '#2161E3',
        width: 3,
        lineDash: [10, 5],
    }),
    fill: new Fill({
        color: 'rgba(255, 0, 0, 1)',
    }),
});

/**
 * Параметр hasData будет true если GPS данные получены с сервера. Если они не получены - рисуем вместо стрелки
 * серый круг
 * @param azimuth
 * @param hasData
 * @returns {Style}
 */
export const getCurrentPosStyle = (azimuth) => new Style({
    image: new Icon({
        src: 'currentPos.png',
        rotation: azimuth * Math.PI / 180,
        rotateWithView: true,
        scale: 0.1
    })
});

export const getCurrentPosUnknownStyle = () => new Style({
    image: new Icon({
        src: 'currentPos--inactive.png',
        scale: 0.1
    })
});

export const getPoiStyle = (label, pointType) => new Style({
    image: new Icon({
        src: pointType === FeatureType.POI ? 'poi.svg' : 'anchor.svg',
        width: pointType === FeatureType.POI ? 40 : 20,
        height: pointType === FeatureType.POI ? 40 : 20,
    }),
    text: new Text({
        text: label,
        fill: new Fill({
            color: 'black'
        }),
        font: `bold ${pointType === FeatureType.POI ? 16 : 13}px Arial`,
        offsetY: pointType === FeatureType.POI ? 30 : -15,
        offsetX: pointType === FeatureType.POI ? 80 : 20,
        backgroundFill: new Fill({
            color: 'rgba(255, 255, 255, 0.7)',
        }),
    })
});
