import {Track} from "../../../api/tracks.d";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import {getTilesUrl} from "../../../api/tracks";
import {makeCurrentPosFeature} from "./currentPosFeature";
import {makePoisFeatures} from "./poiFeatures";
import {makeTrackFeature} from "./trackFeature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as olProj from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import {Feature} from "ol";
import {Geometry} from "ol/geom";
import {FeatureType, MapZoomMode} from "./common";

type MapDescriptor = {
    map: Map;
    currentPositionFeature: Feature
}

declare global {
    interface Window {
        ReactNativeWebView:any;
    }
}

interface NamedLayer extends VectorLayer<VectorSource<Geometry>> {
    id: string;
}

const POI_LAYER_ID = 'poiLayer';
const STOP_LAYER_ID = 'stopLayer';

export const getPoiLayer = (map) => {
    return map.getLayers()
        .getArray()
        .find(layer => layer.id === POI_LAYER_ID);
}

export const getStopLayer = (map) => {
    return map.getLayers()
        .getArray()
        .find(layer => layer.id === STOP_LAYER_ID);
}
export const drawMap = (track: Track, mapRef): MapDescriptor => {

    const bissLayer = new TileLayer({
        source: new OSM({
            opaque: false,
            url: getTilesUrl(track.trackId),
            cacheSize: 1024
        }),
    })

    const currentPositionFeature = makeCurrentPosFeature({
        longitude: track.settings.mapInitialPosition.longitude,
        latitude: track.settings.mapInitialPosition.latitude,
        azimuth: 0
    });

    const poiFeatures = makePoisFeatures(track.pois, FeatureType.POI);
    const stopFeatures = makePoisFeatures(track.stops, FeatureType.STOP);

    const trackFeature = makeTrackFeature(track.points);

    const vectorLayer = new VectorLayer({
        source: new VectorSource({
            features: [trackFeature, currentPositionFeature],
        }),
    });

    const poiLayer: NamedLayer = Object.assign(new VectorLayer({
        source: new VectorSource({
            features: [...poiFeatures],
        }),
    }), {
        id: POI_LAYER_ID,
    });

    const stopLayer: NamedLayer = Object.assign(new VectorLayer({
        source: new VectorSource({
            features: [...stopFeatures],
        }),
    }), {
        id: STOP_LAYER_ID,
    });

    const leftBottom = olProj.fromLonLat(
        [track.settings.mapSouthWestPoint.longitude, track.settings.mapSouthWestPoint.latitude]
    );
    const rightTop = olProj.fromLonLat(
        [track.settings.mapNorthEastPoint.longitude, track.settings.mapNorthEastPoint.latitude]
    );

    const initialMap = new Map({
        target: mapRef.current,
        layers: [
            bissLayer, vectorLayer, poiLayer, stopLayer
        ],
        view: new View({
            maxZoom: track.settings.fragmentZoomLevel,
            minZoom: track.settings.fragmentZoomLevel,
            center: olProj.fromLonLat([track.settings.mapInitialPosition.longitude, track.settings.mapInitialPosition.latitude]), //ширина и долгота тут перепутаны местами!
            zoom: track.settings.fragmentZoomLevel,
            constrainOnlyCenter: true,
            //xmin,ymin,xmax,ymax
            extent: [leftBottom[0], leftBottom[1], rightTop[0], rightTop[1]],
        }),
        controls: []
    });
    initialMap.on('click', function (evt) {
        const feature = initialMap.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
                return feat;
            }
        );

        if (feature && feature.get('type') === FeatureType.POI) {
            const poiDetails = {
                id: feature.get('id')
            };
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'POI_CLICK',
                payload: poiDetails
            }));
        }
    });
    return {
        map: initialMap,
        currentPositionFeature: currentPositionFeature
    };
}
