import * as React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import authProvider from "./auth/authProvider";
import {Admin, Resource} from 'react-admin';
import customRoutes from "./routes/customRoutes";
import {bissDataProvider} from "./api/dataProvider";
import {mainTheme} from "./themes/mainTheme";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import messages from "./messages";
import BissLayout from "./components/layout/BissLayout";
import LoginPage from "./components/authPages/login/LoginPage";
import {
    routeCameras,
    routeCurrentUser,
    routeListFeedback,
    routeManageAudios,
    routeManageImages,
    routeManageInstructions,
    routeManageLogo,
    routeManageUsers,
    routeManageVideos,
    routePrivacyPolicy,
    routeSettings,
    routeTermOfUse,
    routeTiles,
    routeTrackFile,
    routeTripFile
} from "./routes";
import {LogoutButton} from "./components/authPages/logout/LogoutButton";


const i18nProvider = polyglotI18nProvider(() => messages, 'ru', {allowMissing: true});

const initialState = {
    admin: {
        ui: {
            sidebarOpen: authProvider.isAdmin(),
            viewVersion: 0
        }
    }
}
const App = () => {
    return <Router>
        <Admin dataProvider={bissDataProvider}
               customRoutes={customRoutes}
               authProvider={authProvider}
               initialState={initialState}
               i18nProvider={i18nProvider}
               theme={mainTheme}
               layout={BissLayout}
               logoutButton={LogoutButton}
               loginPage={LoginPage}
               disableTelemetry
        >
            {permissions => [
                ...permissions.isAdmin ?
                    [
                        ...routeManageUsers(),
                        ...routeManageImages(),
                        ...routeManageVideos(),
                        ...routeManageAudios(),
                        ...routeListFeedback(),
                        ...routeTrackFile(),
                        ...routeTripFile(),
                        ...routeSettings(),
                        ...routeTiles(),
                        ...routeCameras(),
                        ...routeManageLogo(),
                        ...routeManageInstructions(),
                        ...routePrivacyPolicy(),
                        ...routeTermOfUse()
                    ] : [],    routeManageVideos,

                ...!permissions.isAdmin ? [
                    //чтобы анонимному пользователю открывалась страница логина необходимо чтобы первым в списке ресурсов был "login"
                    <Resource name="login"/>,
                    ...routeCurrentUser()
                ] : []
            ]}
        </Admin>
    </Router>
}

export default App;
