import {BUILD_NUMBER} from "../settings/appsettings";
import {ROUTE_URLS} from "./constants";
import {fetch, getServiceHost} from './httpClient';

export const getTilesUrl = (trackId: string) => {
    return (
        getServiceHost() + '/biss-api/webapp/tiles/get/{z}/{x}/{y}.png?' + BUILD_NUMBER
    );
};

export const fetchTrackConfig = async () =>
    fetch(`/${ROUTE_URLS.TRACK}/get-track-config`, {cache: 'no-cache'})

