import {makeStyles} from "@material-ui/core/styles";

export const useStaffInfoStyles = makeStyles({
    container: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-around"
    },
    personBox: {
        display: "flex",
        flexDirection: 'column',
        width: '25vw',
        alignContent: 'space-between',
        justifyContent: "space-between",
    },
    imageBox: {
        width: '20vw',
        aspectRatio: '1/1',
        overflow: "hidden",
        borderRadius: '10px 10px 0 0',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: "cover"
    }
})
