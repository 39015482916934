export const setPageName = page => {
    document.title = page
}

export const formatFileSize = size => {
    const roundNumber = num => Math.round((num + Number.EPSILON) * 100) / 100
    return size <= 1024
        ? `${size} байт`
        : size <= 1024 * 1024 && size > 1024
            ? `${roundNumber(size / 1024)} кб`
            : size <= 1024 * 1024 * 1024 && size > 1024 * 1024
                ? `${roundNumber(size / 1024 / 1024)} мб`
                : `${roundNumber(size / 1024 / 1024 / 1024)} гб`
}

export const base64toBlob = (base64File) => {
    let byteString = base64File.split(',')[0].indexOf('base64') >= 0
        ? atob(base64File.split(',')[1])
        : decodeURIComponent(base64File.split(',')[1]);

    let mimeString = base64File.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return URL.createObjectURL(new Blob([ia], {type: mimeString}));
}

export const convertBlobToFile = (blob, fileName) =>
    new File([blob], fileName, {type: blob.type, path: fileName})


export const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = blob;
    link.setAttribute('download',fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        if (!isFile(file)) {
            //файл не изменили.
            resolve(null);
            return;
        }
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(extractFile(file));
    });

export const isFile = (attachment) => {
    const file = extractFile(attachment)
    return !!file;
}

const extractFile = (attachment) => {
    if (!attachment) {
        return null;
    }
    if (attachment instanceof File) {
        return attachment; //это для загрузки через чистый React (без Marmelab)
    }
    if (attachment.rawFile && attachment.rawFile instanceof File) {
        return attachment.rawFile;
    }
}

export const parseFileNameFromContentDisposition = (disposition) => {
    let filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
        filename = decodeURIComponent(filename)
    }
    return filename;
}

export const getFormatTime = (dateString) => {
    const date = new Date(dateString);

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        weekday: 'short'
    };

    return  date.toLocaleTimeString('ru-RU', timeOptions);
}

export const getFormatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
        day: '2-digit',
        month: 'short',
        weekday: 'short'
    };

    return  date.toLocaleDateString('ru-RU', options);
}

export const getFormatDateTime = (dateString) => {
    const date = new Date(dateString);

    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: "2-digit",
        minute: "2-digit",
    };

    return  date.toLocaleDateString('ru-RU', options);
}
