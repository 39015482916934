import {makeStyles} from "@material-ui/core/styles";

export const useFileUploadFormStyles = makeStyles({
    paper: {
        overflow: "hidden"
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        gap: '2em',
        justifyContent: 'space-between',
        height: '100%'
    },
    tilesStatisticsContainer: {
        width: '100%'
    },
    loadingContainerDialog: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 5
    },
    actions: {
        display: "flex",
        justifyContent: "end"
    },
})
