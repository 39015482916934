import {Box, Typography} from "@mui/material";
import {usePolicyStyles} from "./policyStyles";
import {Link} from "@material-ui/core";
import {loadCurrentApplicationDocument} from "../../api/applicationDocument";
import {downloadFile, parseFileNameFromContentDisposition} from "../../api/utils";
import {APPLICATION_DOCUMENT_TYPES} from "../../api/constants";

export const Policy = () => {
    const classes = usePolicyStyles()
    const onDownloadClick = () => {
        loadCurrentApplicationDocument(APPLICATION_DOCUMENT_TYPES.PRIVACY_POLICY)
            .then((request) => {
                const blob = URL.createObjectURL(request.data);
                const fileName = parseFileNameFromContentDisposition(request.headers['content-disposition']);
                downloadFile(blob, fileName);
            });
    }
    return <Box className={classes.container}>
        <Typography variant={'h6'} align={"center"} >
            <Link underline="always" onClick={onDownloadClick} >
                Скачать политику конфиденциальности приложения (PDF)
            </Link>
        </Typography>
    </Box>
}
