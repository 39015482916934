import * as React from "react";
import {useErrorStyles} from "./styles";
import {Alert} from "@mui/material";

type ErrorMessageProps = {
    message: string;
};
export const ErrorMessage = (props: ErrorMessageProps) => {
    const classes = useErrorStyles();
    return props.message ?
        <Alert severity="error" className={classes.errorMessage}>{props.message}</Alert> : <></>
}
