import {API_PATH_WEBAPP} from "../settings/appsettings";

export const LOCAL_STORAGE_AUTH = 'auth';
export const MAP_PARAM_FOR_MOBILE = 'for-mobile'
export const MAP_PARAM_ZOOM_MODE = 'zoom-mode';
export const MAP_PARAM_SCREEN_WIDTH = 'screen-width';
export const MAP_PARAM_SCREEN_HEIGHT = 'screen-height';

export const AUTH_RESPONSE = {
    OK: 'OK',
    BAD_CREDENTIALS: 'BAD_CREDENTIALS'
}

export const ROUTE_URLS = {
    USERS: API_PATH_WEBAPP + '/users',
    ADMINS: API_PATH_WEBAPP + '/admins',
    HOME: '/#/',
    LOGIN: '/' + API_PATH_WEBAPP + '/auth/login',
    CURRENT_POSITION: API_PATH_WEBAPP + '/tracker/get-current-position',
    UPLOAD_IMAGE: API_PATH_WEBAPP + '/images',
    UPLOAD_VIDEO: API_PATH_WEBAPP + '/videos',
    UPLOAD_AUDIO: API_PATH_WEBAPP + '/audios',
    UPLOAD_INSTRUCTIONS: API_PATH_WEBAPP + '/instructions',
    INSTRUCTIONS_API_METADATA: API_PATH_WEBAPP + '/instructions/metadata',
    UPLOAD_LOGO: API_PATH_WEBAPP + '/logo',
    GET_LATEST_LOGO: API_PATH_WEBAPP + '/logo/get-latest',
    TRACK: API_PATH_WEBAPP + '/track',
    TRIP: API_PATH_WEBAPP + '/trip',
    SETTINGS: API_PATH_WEBAPP + '/settings',
    TILES: API_PATH_WEBAPP + '/tiles',
    TERM_OF_USE: API_PATH_WEBAPP + '/term-of-use-route',
    TERM_OF_USE_API: API_PATH_WEBAPP + '/term-of-use',
    TERM_OF_USE_API_METADATA: API_PATH_WEBAPP + '/term-of-use/metadata',
    PRIVACY_POLICY: API_PATH_WEBAPP + '/privacy-policy-route',
    PRIVACY_POLICY_API: API_PATH_WEBAPP + '/privacy-policy',
    PRIVACY_POLICY_API_METADATA: API_PATH_WEBAPP + '/privacy-policy/metadata',
    TILES_STATS: API_PATH_WEBAPP + '/tiles/stats',
    CRUISE_CARD: API_PATH_WEBAPP + '/cruise-card',
    CAMERAS: API_PATH_WEBAPP + '/camera',
    SETTINGS_CURRENT: API_PATH_WEBAPP + '/settings/get-current',
    BLOB: API_PATH_WEBAPP + '/blob',
    FEEDBACK: API_PATH_WEBAPP + '/feedback',
    GET_IMAGE_BY_ID: '/' + API_PATH_WEBAPP + '/images/getById/?id=',
}

export const applicationName = "Бортовая информационно-справочная система"
export const PAGE_NAMES = {
    USERS_LIST:         `Пользователи`,
    ADMINS_LIST:        `Администраторы`,
    USER_CREATE:        `Новый пользователь`,
    ADMIN_CREATE:       `Новый администратор`,
    USER:               `Пользователь`,
    ADMIN:              `Администратор`,
    IMAGE:              `Изображения`,
    VIDEO:              `Видеофайлы`,
    AUDIO:              `Аудиофайлы`,
    LOGO:               `Логотипы`,
    FEEDBACK:           `Обратная связь`,
    INSTRUCTIONS:       `Руководство по приложению`,
    TRACK:              `Маршрут`,
    CAMERA:             `Подключение бортовых видеокамер`,
    TRIP:               `Рейс`,
    LOGIN:              `${applicationName} | Вход`,
    SETTINGS:           `Настройки`,
    TILES:              `Тайлы карт`,
    PRIVACY_POLICY:     `Политика конфиденциальности`,
    TERM_OF_USE:        `Пользовательское соглашение`,
    CRUISE_CARD:        `Круизные карты`,
    CAMERAS_LIST:       `Бортовые видеокамеры`,
    POI_CREATE:         `Новая достопримечательность`,
    CAMERA_CREATE:      `Добавить камеру`,
}

export const USER_ROLE = {
    ADMIN: 'ADMIN',
    USER: 'USER',
}

export const HTTP_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

export const MIME_TYPES = {
    IMAGE: 'image/jpeg, image/png',
    VIDEO: 'video/mp4',
    AUDIO: 'audio/*',
    PDF: 'application/pdf',
    JSON: 'application/JSON',
    ARCHIVE: 'application/zip, application/x-zip-compressed, multipart/x-zip, application/zip-compressed, application/x-zip'
}

export const APPLICATION_DOCUMENT_TYPES = {
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    TERMS_OF_USE: 'TERMS_OF_USE'
}

export const FEEDBACK_MARKS = {
    PERFECT: 'Отлично',
    GOOD: 'Хорошо',
    NORMAL: 'Нормально',
    BAD: 'Плохо'
}
