import {makeStyles} from "@material-ui/core/styles";
import lodashGet from "lodash/get";
import {CLOSED_MENU_WIDTH, MENU_WIDTH} from "ra-ui-materialui/lib/layout/Menu";
import {shallowEqual, useSelector} from "react-redux";
import {getResources, useGetResourceLabel} from "ra-core";
import {DashboardMenuItem} from "ra-ui-materialui";
import {MenuItemLink} from "react-admin";
import classnames from "classnames";

const useStyles = makeStyles(
    theme => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '0.5em',
            marginBottom: '1em',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        open: {
            width: lodashGet(theme, 'menu.width', MENU_WIDTH),
        },
        closed: {
            width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
        },
    }),
    { name: 'RaMenu' }
);

function DefaultIcon() {
    return null;
}

const BissMenu = (props) => {
    const resources = useSelector(getResources, shallowEqual);
    const getResourceLabel = useGetResourceLabel();
    const {
        hasDashboard,
        dense,
        children = (
            <>
                {hasDashboard && <DashboardMenuItem dense={dense} />}
                {resources
                    //переменная hidden отвечает за скрытие роута из левого меню
                    //весь этот класс был скопирован из Menu.tsx из react admin
                    //для того чтобы реализовать поддержку этой переменной
                    .filter(r => r.hasList && !r.options.hidden )
                    .map(resource => (
                        <MenuItemLink
                            key={resource.name}
                            to={{
                                pathname: `/${resource.name}`,
                                state: { _scrollToTop: true },
                            }}
                            primaryText={getResourceLabel(resource.name, 2)}
                            leftIcon={
                                resource.icon ? (
                                    <resource.icon />
                                ) : (
                                    <DefaultIcon />
                                )
                            }
                            dense={dense}
                        />
                    ))}
            </>
        ),
        classes: classesOverride,
        className,
        onMenuClick,
        logout,
        ...rest
    } = props;
    const classes = useStyles(props);
    const open = useSelector((state) => state.admin.ui.sidebarOpen);

    return (
        <div
            className={classnames(
                classes.main,
                {
                    [classes.open]: open,
                    [classes.closed]: !open,
                },
                className
            )}
            {...rest}
        >
            {children}
        </div>
    );
};
export default BissMenu;