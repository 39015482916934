import {useEffect} from 'react';
import {LOCAL_STORAGE_AUTH} from "../../../api/constants";
import {useRedirect} from "react-admin";

const LogoutPage = () => {
    const redirect = useRedirect();

    useEffect(() => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH);
        redirect('login');
    });
    return null;
}

export default LogoutPage;