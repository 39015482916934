import * as React from "react";
import NavigationIcon from "@mui/icons-material/Navigation";
import {useNavButtonStyles} from "./styles";
import {COLORS} from "../../../../themes/colors";

type NavigationModeClickCallback = () => void;


export type NavButtonProps = {
    navigationMode: NavigationMode;
    onNavigationModeClick: NavigationModeClickCallback;
};

export enum NavigationMode {
    Follow, //карта скроллится вместе с движением судна, т.е. судно всегда в середине
    Free
}
export const NavModeButton = (props: NavButtonProps) => {
    const classes = useNavButtonStyles();
    return <div className={classes.positionBtn }
                            onClick={() => {props.onNavigationModeClick();}}>
        <NavigationIcon
            style={{
                color: props.navigationMode === NavigationMode.Follow ? COLORS.TRACK : '#222',
        }}
        />
    </div>
}
