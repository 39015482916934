import {Box, Typography} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox'
import {CreateButton as CreateButtonRa} from "react-admin"
import CreateButton from "./CreateButton";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types'

const iconSize = '9em'
const useEmptyPageStyle = makeStyles({
    icon: {
        minHeight: iconSize,
        minWidth: iconSize
    },
    entireComponent: {
        color: 'rgba(0,0,0,0.5)',
        textAlign: 'center',
    },
    createButton: {
        display: "flex",
        justifyContent: 'end'
    }
})

const EmptyPage = ({header, description, onCreate, customCreateMenu, notHasCreate}) => {
    const classes = useEmptyPageStyle()
    return <Box>
        {!notHasCreate && <Box className={classes.createButton}>
                {customCreateMenu ?
                    customCreateMenu
                    : onCreate
                        ? <CreateButton onClick={onCreate}>
                            Создать
                        </CreateButton>
                        : <CreateButtonRa/>}
            </Box>}
        <Box className={classes.entireComponent}>
            <InboxIcon className={classes.icon}/>
            <Typography variant="h4" paragraph>
                {header}
            </Typography>
            {description && <Box>
                <Typography variant="body1">
                    {description}
                </Typography>
                <br/>
            </Box>}
        </Box>
    </Box>
}
EmptyPage.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.any,
    onCreate: PropTypes.func,
    customCreateMenu: PropTypes.element,
    notHasCreate: PropTypes.bool
}

EmptyPage.defaultProps = {
    notHasCreate: false
}
export default EmptyPage;
