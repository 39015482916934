import {makeStyles} from "@material-ui/core/styles";

export const useNavButtonStyles = makeStyles({
    positionBtn: {
        position: 'fixed',
        top: '40vh',
        right: '1vh',
        border: '1px solid #CCC',
        padding: '0.5vw',
        borderRadius: '3vw',
        zIndex: 100,
        background: '#FFF'
    }
});
