import {Datagrid, DateField, List, TextField} from "react-admin";
import * as React from "react";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import EmptyPage from "../../components/EmptyPage";

export const ListTrack = ({...props}) => {
    return <Box>
        <PageTitle header={pageNaming.track.list.header} description={pageNaming.track.list.description}/>
        <List {...props}
              exporter={false}
              bulkActionButtons={false}
              empty={<EmptyPage header={'Нет маршрутов'}/>}
        >
            <Datagrid>
                <TextField source={'id'} label={'ID'}/>
                <DateField source="createdAt" label="Дата загрузки" locales={'ru'} showTime/>
            </Datagrid>
        </List>
    </Box>
}
