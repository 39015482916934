import {makeStyles} from "@material-ui/core/styles";

export const useStyles  = makeStyles({
    editFileUploadContainer: {
        width: '100%',
        display: 'flex'
    },
    editFileUploadLeftPane: {
        width: '63%',
        paddingRight: '20px'
    },
    image: {
        height: 50
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
})
