import {Route} from "react-router-dom";
import * as React from "react";
import LogoutPage from "../components/authPages/logout/LogoutPage";
import {MapPage} from "./map/MapPage.tsx";
import {Cruise} from "./cruise/cruise/Cruise";
import {Policy} from "./policy/Policy";
import {TermOfUse} from "./termOfUse/TermOfUse";

const reload = () => window.location.reload();


const customRoutes = [
    <Route noLayout path="/logout" render={() =>
        <LogoutPage/>
    }/>,
    <Route noLayout path="/map" render={() =>
        <MapPage/>
    }/>,
    <Route noLayout path="/cruise" render={() =>
        <Cruise/>
    }/>,
    <Route noLayout path="/policy" render={() =>
        <Policy/>
    }/>,
    <Route noLayout path="/terms" render={() =>
        <TermOfUse/>
    }/>,
    /*Это нужно чтобы файл tiles-archive-sample.zip можно было скачать
    как статический ресурс из папки public*/
    <Route path="/tiles-archive-sample.zip" onEnter={reload} />
]
export default customRoutes;
