import {Box, Typography} from "@mui/material";
import 'react-slideshow-image/dist/styles.css';
import {Card} from "../../../components/Card";
import {useImageSlidesStyles} from "./shipInfoStyles";

export const ShipInfo = ({shipInfoData}) => {
    const classes = useImageSlidesStyles()

    return <Box>
        <Typography variant={'h3'}>
            {shipInfoData.name}
        </Typography>
        <Card>
            <Typography variant={'h6'}>
                <Typography variant={'h6'}
                            component={'span'}
                            style={{fontWeight: 800}}>Класс теплохода: </Typography>
                {shipInfoData.shipClass}
            </Typography>
            <hr className={classes.hr}/>
            <Typography variant={'h6'}
                        component={'span'}
                        style={{fontWeight: 800}}
            >
                {shipInfoData.type}
            </Typography>
            <Typography className={classes.certificate}>
                (сертификат соответствия {shipInfoData.certificate})
            </Typography>
            <hr className={classes.hr}/>
            <Typography style={{fontFamily: 'serif'}}>
                {shipInfoData.description}
            </Typography>
        </Card>
    </Box>
}
