import {useVersion} from "react-admin";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import * as React from "react";
import {useEffect, useState} from "react";
import {MIME_TYPES} from "../../api/constants";
import {loadCurrentApplicationDocumentMetadata} from "../../api/applicationDocument";
import {FileUploadForm} from "../../components/fileUpload/FileUploadForm";
import {base64toBlob, downloadFile} from "../../api/utils";
import {Link} from "@material-ui/core";

export const ApplicationDocument = ({apiUrl, title, description, applicationDocumentType}) => {
    const [currentDocument, setCurrentDocument] = useState(null);
    const version = useVersion();

    const reloadCurrentDocument = () => {
        loadCurrentApplicationDocumentMetadata(applicationDocumentType)
            .then((request) => {
                setCurrentDocument(request.data);
            });
    }

    useEffect(() => {
        reloadCurrentDocument();
    }, [version]);

    return <Box>
        <PageTitle header={title}
                   description={description}/>
        <FileUploadForm accept={MIME_TYPES.PDF}
                        sendWithBase64={true}
                        resource={apiUrl}
                        label={''}
                        aside={currentDocument &&
                            <Link onClick={() =>
                                downloadFile(base64toBlob(currentDocument.blobDto.base64File), currentDocument.blobDto.fileName)}
                                  style={{cursor: 'pointer'}}
                            >
                                Текущий документ: {currentDocument.blobDto.fileName}
                            </Link>}
        />
    </Box>
}
