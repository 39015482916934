import {makeStyles} from "@material-ui/core/styles";

export const useFormStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(3),
        width: '300px',
        flexWrap: 'wrap',
    },
    container: {
        display: "flex",
        margin: '0',
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "column",
        width: "100%"
    },
    inputFields: {
        width: "100%"
    }
}))

export const useModalStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        marginLeft: "15px",
        marginBottom: "15px",

    },
    outlineButtons: {
        "&:hover": {
            backgroundColor: 'transparent',
            color: '#253b4d'
        }
    },
}))