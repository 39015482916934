import {makeStyles} from "@material-ui/core/styles";

export const useHeaderStyles = makeStyles({
    container: {
        backgroundColor: 'rgb(255,255,255)',
        padding: '0 1rem 1rem 0',
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        maxWidth: '100% !important'
    },
    logoAndTitle: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "end",
        gap: "5px"
    },
    title: {
        fontWeight: "bold",
        fontSize: '27px',
        color: '#00749d'
    },
    imageContainer: {
        height: '100%',
        display: "flex",
        aspectRatio: '16/9'
    },
    sliderContainer: {
      backgroundColor: 'rgb(238, 238, 238)'
    },
    logo: {
        height: '50px',
    }
})
