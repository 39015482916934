import {MapLimits} from "../../api/tracks.d";

type MapProps = {
    bounds: MapLimits;
    screenResolution: { width: number; height: number };
    availableZoomLevels: Array<number>;
};

const TILE_WIDTH = 256;
const TILE_HEIGHT = 256;
const INCHES_PER_METER = 156543.03392;
const EARTH_RADIUS = 6371e3;
export const calculateZoomLevelForResolution = ({
                                                    bounds,
                                                    screenResolution,
                                                    availableZoomLevels,
                                                }: MapProps): number => {
    const lat1 = bounds.mapSouthWestPoint.latitude * Math.PI / 180;
    const lat2 = bounds.mapNorthEastPoint.latitude * Math.PI / 180;
    const lon1 = bounds.mapSouthWestPoint.longitude * Math.PI / 180;
    const lon2 = bounds.mapNorthEastPoint.longitude * Math.PI / 180;

    const phi1 = lat1;
    const phi2 = lat2;
    const deltaPhi = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a =
        Math.sin(deltaPhi / 2) ** 2 +
        Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const latDistance = EARTH_RADIUS * c;
    const lonDistance = EARTH_RADIUS * Math.cos((lat1 + lat2) / 2) * deltaLon;
    const maxDimMeters = Math.sqrt(latDistance ** 2 + lonDistance ** 2);

    const screenDiagonalPx = Math.sqrt(screenResolution.width ** 2 + screenResolution.height ** 2);
    const metersPerPixel = maxDimMeters / screenDiagonalPx;

    let lo = 0;
    let hi = 18;

    while (lo < hi - 1) {
        const mid = Math.floor((lo + hi) / 2);
        const widthMeters = (TILE_WIDTH * INCHES_PER_METER * Math.cos((lat1 + lat2) / 2)) / 2 ** mid;
        const heightMeters = (TILE_HEIGHT * INCHES_PER_METER) / 2 ** mid;

        if (widthMeters / metersPerPixel < screenResolution.width && heightMeters / metersPerPixel < screenResolution.height) {
            hi = mid;
        } else {
            lo = mid;
        }
    }

    return hi !== null && availableZoomLevels.includes(hi) ? hi : null;
};
