import {APPLICATION_DOCUMENT_TYPES, HTTP_METHOD, ROUTE_URLS} from "./constants";
import {fetch} from "./httpClient";

export const loadCurrentApplicationDocument = (applicationDocumentType) => {
    let url;
    switch (applicationDocumentType) {
        case APPLICATION_DOCUMENT_TYPES.PRIVACY_POLICY:
            url = `/${ROUTE_URLS.PRIVACY_POLICY_API}`
            break;
        case APPLICATION_DOCUMENT_TYPES.TERMS_OF_USE:
            url = `/${ROUTE_URLS.TERM_OF_USE_API}`
            break;
        default: return Promise.reject('URL not defined')
    }

    return fetch(url, {
        method: HTTP_METHOD.GET,
        responseType: 'blob'
    });
}

export const loadCurrentApplicationDocumentMetadata = (applicationDocumentType) => {
    let url;
    switch (applicationDocumentType) {
        case APPLICATION_DOCUMENT_TYPES.PRIVACY_POLICY:
            url = `/${ROUTE_URLS.PRIVACY_POLICY_API_METADATA}`
            break;
        case APPLICATION_DOCUMENT_TYPES.TERMS_OF_USE:
            url = `/${ROUTE_URLS.TERM_OF_USE_API_METADATA}`
            break;
        default: return Promise.reject('URL not defined')
    }

    return fetch(url, {
        method: HTTP_METHOD.GET,
    });
}
