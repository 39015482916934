import {Toolbar} from "react-admin";
import {Button} from "@material-ui/core";
import {FloppyIcon, RedoIcon} from "../icons/iconsFactory";

export const SaveCancelToolbar = ({onClick, submitDisabled, hasCancel = false, ...props}) => {
    return <Toolbar {...props}>
        <Button startIcon={<FloppyIcon/>}
                onClick={onClick}
                disabled={submitDisabled}
        >
            Сохранить
        </Button>
        {hasCancel && <Button startIcon={<RedoIcon/>}
                 onClick={() => window.history.back()}
        >
            Назад
        </Button>}
    </Toolbar>
}
