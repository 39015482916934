import russianMessages from 'ra-language-russian';
import {applicationName} from "./api/constants";

const messages = {
    ...russianMessages,
    ra: {
        ...russianMessages.ra,
        message: {
            ...russianMessages.ra.message,
            delete_content: 'Это действие удалит объект, и его нельзя отменить'
        },
        action: {
            ...russianMessages.ra.action,
            move_up: 'Переместить наверх',
            move_down: 'Переместить вниз'
        }
    },
    error: {
        loginFailed: 'Ошибка: неверный логин или пароль',
        'USER_DISABLED': 'Учетная запись заблокирована, обратитесь к Администратору',
        applicationUnavailable: 'Ошибка: сервер приложения недоступен',
        authLimit: 'Исчерпан лимит попыток входа в "'+ applicationName +'". Обратитесь к Администратору для восстановления доступа'
    },
    event: {
        userProfile: {
            details: {
                id: 'ID',
                name: 'Имя',
                login: 'Логин',
                role: 'Роль',
                isDisabled: 'Статус учетной записи',
            }
        },
    },
    user: {
        status: {
            active: 'Активна',
            disabled: 'Заблокирована'
        },
        role: {
            USER: 'Пользователь',
            ADMIN: 'Администратор'
        },
    },
    sys: {
        sync: 'Синхронизация запущена'
    }
}

export default messages;