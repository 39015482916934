import * as React from 'react';
import {useState} from 'react';
import {Notification, useLogin, useNotify} from 'react-admin';
import {Box} from '@mui/material';
import {useAuthPagesStyles} from "../authPagesStyles";
import {TextInput} from "../../TextInput";
import {PasswordInput} from "../../PasswordInput";
import {AuthButton} from "../AuthButton";
import {setPageName} from "../../../api/utils";
import {PAGE_NAMES} from "../../../api/constants";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const login = useLogin();
    setPageName(PAGE_NAMES.LOGIN);
    const handleSubmit = e => {
        setLoading(true);
        e.preventDefault();
        login({username, password}).then(() => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            notify(error, {type: 'warning'});
        })
    };

    const classes = useAuthPagesStyles();

    return (
        <Box className={classes.body}>
            <Box className={classes.login}>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Box className={classes.form}>
                        <TextInput label='Имя пользователя'
                                   variant='standard'
                                   required
                                   onChange={e => setUsername(e.target.value)}
                        />
                        <PasswordInput label='Пароль'
                                       variant='standard'
                                       required
                                       type='password'
                                       onChange={e => setPassword(e.target.value)}
                        />
                    </Box>
                    <AuthButton type='submit'
                                loading={loading}
                    >
                        Войти
                    </AuthButton>
                </form>
            </Box>
            <Notification/>
        </Box>
    );
};
export default LoginPage

