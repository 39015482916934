import {fetchUtils} from 'react-admin';
import {HTTP_METHOD, LOCAL_STORAGE_AUTH, ROUTE_URLS} from "./constants";
import axios from "axios";
import messages from "../messages";
import {AUTHENTICATION_SERVICE_HOST} from "../settings/appsettings";

const httpClient = (url, options = {}) => {
    enrichHeaders(options);

    return fetchUtils.fetchJson(getServiceHost(url) + url, options)
        .then((response) => {
            if (response.headers.get('authorization') && !localStorage.getItem(LOCAL_STORAGE_AUTH)) {
                localStorage.setItem(LOCAL_STORAGE_AUTH, response.headers.get('authorization'));
            }
            return Promise.resolve(response);
        })
        .catch(e => {
            if (e?.message === 'Failed to fetch') {
                throw new Error(messages.error.applicationUnavailable);
            }
            throw e;
        });
};

export const fetch = (url, options = {}) => {
    enrichHeaders(options);
    //https://github.com/axios/axios/issues/815#issuecomment-340972365
    let request = axios.create();
    return request({
        method: options?.method || HTTP_METHOD.GET,
        url: getServiceHost(url) + url,
        headers: Object.fromEntries(options?.headers.entries()),
        params: options?.params,
        data: options?.data,
        responseType: options?.responseType
    });
}


export default httpClient;

export const anonymousQuery = axios.create({
    baseURL: AUTHENTICATION_SERVICE_HOST
});

export const getServiceHost = () => {
    return AUTHENTICATION_SERVICE_HOST;
};

const enrichHeaders = options => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    options.headers.set('authorization', 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_AUTH));
}
