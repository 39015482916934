// noinspection DuplicatedCode

import {Box, Paper} from "@mui/material";
import {FileInput} from "./FileInput";
import {SaveCancelToolbar} from "../SaveCancelToolbar";
import * as React from "react";
import {useState} from "react";
import {useFileUploadFormStyles} from "./fileUploadStyles";
import {FileUploadingDialog} from "./FileUploadingDialog";
import {useNotify, useRefresh} from "react-admin";
import PropTypes from "prop-types";
import {HTTP_METHOD} from "../../api/constants";
import {fetch} from "../../api/httpClient";
import _ from "lodash";
import {convertFileToBase64} from "../../api/utils";

/**
 * Компонент для отображения формы загрузка как в ReactAdmin, но построенная на MUI компонентах
 *
 * @param children Элементы которые располагаются после поля файла
 * @param actions Элементы в правом верхнем углу компонента
 * @param aside Элементы в левом верхнем углу компонента над полем ввода файла
 * @param resource Путь для отправки запроса без домена
 * @param accept разрешенные типы файлов
 * @param sendWithBase64 Если `true` файл отправляется на сервер предварительно зашифрованный в Base64,
 *          если false, то файл отправляется потоком данных
 * @param httpMethod Тип http запроса отправки файла
 * @param label Текст - подсказка над полем ввода файла
 * */

export const FileUploadForm = ({
                                   children,
                                   actions,
                                   aside,
                                   accept = '*',
                                   resource,
                                   sendWithBase64,
                                   httpMethod = HTTP_METHOD.POST,
                                   label = "Выберите файл"
                               }) => {
    const classes = useFileUploadFormStyles()
    const [files, setFiles] = useState([]);
    const [fileUploadStarted, setFileUploadStarted] = useState(false);
    const notify = useNotify()
    const refresh = useRefresh()

    const successUpload = () => {
        notify('Файл успешно загружен');
        refresh()
    }

    const errorUpload = (error) => {
        const msg = error?.response?.data?.error;
        notify('Ошибка при загрузке файла: ' + msg ?? error, {type: 'error'});
    }

    const uploadWithFormData = () => {
        const formData = new FormData();
        formData.append('file', files[0]);
        return uploadFile(resource, formData)
    }

    const uploadWithBase64 = () => {
        return convertFileToBase64(files[0])
            .then(base64File => {
                    let data = {
                        fileName: `${files[0].name}`,
                        sizeBytes: `${files[0].size}`,
                        base64File: base64File
                    }
                    return uploadFile(resource, data)
                }
            );
    }

    const uploadFile = (resource, data) => {
        return fetch(`/${resource}`, {
            method: httpMethod,
            data: data
        })
            .then(successUpload)
            .catch(errorUpload)
            .finally(() => setFileUploadStarted(false));
    }

    const handleFileUpload = () => {
        if (!files.length > 0) {
            notify('Добавьте файл для загрузки');
            return;
        }
        setFileUploadStarted(true);
        sendWithBase64
            ? uploadWithBase64()
            : uploadWithFormData()
    };

    return <Box>
        {actions && <Box className={classes.actions}>
            {actions}
        </Box>}
        <Paper className={classes.paper}>
            <Box className={classes.container}>
                {aside}
                <FileInput onChange={props => {
                    setFiles(props)
                }}
                           files={files}
                           label={label}
                           accept={accept}
                />
                {children}
            </Box>
            <SaveCancelToolbar onClick={handleFileUpload}
                               submitDisabled={!files.length > 0}
            />
        </Paper>
        <FileUploadingDialog open={fileUploadStarted}/>
    </Box>
}

FileUploadForm.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    children: PropTypes.any,
    httpMethod: PropTypes.oneOf(_.values(HTTP_METHOD)),
    label: PropTypes.string,
    accept: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    sendWithBase64: PropTypes.bool.isRequired,
}
