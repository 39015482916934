import * as React from "react";
import {Create, SaveButton, Toolbar, useNotify,} from 'react-admin';
import UserDetailsForm from "./UserDetailsForm";
import {FloppyIcon} from "../../icons/iconsFactory";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {USER_ROLE} from "../../api/constants";

export const CreateUser = props => {

    const notify = useNotify();

    const onFailure = (error) => {
        console.log('ERROR')
        notify(error.message, {type: 'warning'});
    };

    const SaveToolbar = (props) => {
        const {
            handleSubmit,
            handleSubmitWithRedirect,
            onSave,
            invalid,
            pristine,
            saving,
        } = props;

        return <Toolbar {...props} >
            <SaveButton icon={<FloppyIcon/>}
                        handleSubmit={handleSubmit}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        onSave={onSave}
                        invalid={invalid}
                        pristine={pristine}
                        saving={saving}
                        variant="text"
                        submitOnEnter={false}/>
        </Toolbar>;
    }

    return <Box>
        <PageTitle header={(props.role === USER_ROLE.USER ? pageNaming.users : pageNaming.admins).create.header}
                   description={(props.role === USER_ROLE.USER ? pageNaming.users : pageNaming.admins).create.description}
        />
        <Create {...props} onFailure={onFailure} mutationMode="pessimistic">
            <UserDetailsForm isNew={true}
                             role={props.role}
                             toolbar={<SaveToolbar/>}
                             permissions={props.permissions}/>
        </Create>
    </Box>;
}
