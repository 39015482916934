import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SaveButton} from 'react-admin';
import {FloppyIcon} from "../../icons/iconsFactory";
import {useModalStyles} from "./userFormStyles";

export default function ConfirmPasswordDialog({
                                                  open, onClose, handleSubmit, handleSubmitWithRedirect, onSave,
                                                  invalid, pristine, saving
                                              }) {

    const [currentPassword, setCurrentPassword] = React.useState('');

    const transform = data => ({
        ...data,
        currentPassword: currentPassword
    });

    const classes = useModalStyles()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Подтвердите изменения</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Введите Ваш текущий пароль чтобы подтвердить изменения
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    label="Текущий пароль"
                    type="password"
                    fullWidth
                    variant="filled"
                    value={currentPassword}
                    onChange={e => setCurrentPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions className={classes.wrapper}>
                <SaveButton icon={<FloppyIcon/>}
                            handleSubmit={handleSubmit}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            onSave={onSave}
                            invalid={invalid}
                            pristine={pristine}
                            saving={saving}
                            transform={transform}
                            variant="outlined"
                            submitOnEnter={true}
                            className={classes.outlineButtons}

                />
                <Button onClick={onClose}
                        variant="outlined"
                        className={classes.outlineButtons}
                >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}
