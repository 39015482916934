import merge from "lodash/merge";
import {defaultTheme} from "react-admin";
import {lightGreen, pink, red, yellow} from "@material-ui/core/colors";

export const mainTheme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: '#1a2936'
        },
        secondary: pink,
        error: red,
        success: lightGreen,
        warning: yellow,
        contrastThreshold: 3,
        tonalOffset: 0.2,
        body: red
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Roboto, \'Noto Sans\',Helvetica,Arial,Lucida,sans-serif'].join(','),
    },
    sidebar: {
        width: 330,
    },
    menu: {
        width: 330,
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#929da6',
                backgroundColor: '#1a2936'
            },
            positionFixed: {
                transform: 'none!important',
                visibility: 'visible!important'
            }
        },
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'black', // Some CSS
            },
            textPrimary: {
                color: 'black'
            },
            outlinedPrimary: {
                border: '1px transparent solid',
                color: '#929da6',
                '&:hover': {
                    border: '1px transparent solid',
                    color: 'rgb(209, 216, 222)',
                    backgroundColor: 'rgb(134, 146, 155)'
                }
            },
            outlined: {
                border: '1px transparent solid',
                color: '#929da6',
                '&:hover': {
                    border: '1px transparent solid',
                    color: 'rgb(209, 216, 222)',
                    backgroundColor: 'rgb(134, 146, 155)'
                }
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '100%',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#f5f6f7',
                borderRadius: '3px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#cfd6db',
                '&&.Mui-focused': {
                    borderColor: 'rgb(146, 157, 166)',
                }
            },
            underline: {
                '&&::before': {
                    borderBottom: 'none'
                },
                '&&::after': {
                    borderBottom: 'none'
                }
            },
            inputMarginDense: {
                paddingTop: '20px',
                paddingBottom: '1px'
            }
        },
        MuiFormLabel: {
            root: {
                color: '#8f9ea8',
            }
        },
        MuiInputBase: {
            formControl: {
                '&& .MuiSelect-iconFilled': {
                    right: '5px'
                }
            }
        },
        MuiListItem: {
            button: {
                '&&:hover': {
                    backgroundColor: 'rgba(26,41,54,1)',
                    color: 'rgba(245,246,247,1)',
                    '&& svg': {
                        fill: 'rgba(245,246,247,1)'
                    }
                },
                '&&:active': {
                    backgroundColor: 'rgba(26,41,54,1)',
                    color: 'rgba(245,246,247,1)',
                    '&& svg': {
                        fill: 'rgba(245,246,247,1)'
                    }
                },
            }
        },
        MuiMenu: {
            paper: {
                backgroundColor: "#253b4d",
                color: "white"
            },
            root: {
                color: '#fff'
            }
        },
        MuiMenuItem: {
            root: {
                color: '#929da6',

                "&:active": {
                    color: '#f5f6f7',
                    backgroundColor: '#1a2936'
                },
                '&&.Mui-selected': {
                    color: '#f5f6f7',
                }
            }
        },
        MuiTable: {
            root: {
                tableLayout: 'fixed',
                width: '100%',
            }
        },
        MuiTableRow: {
            root: {
                height: '48px',
                '&&.expanded-row .MuiTableCell-body .MuiTypography-root': {
                    color: 'rgb(26, 41, 54)',
                    fontWeight: '600'
                },
                '&&.expanded-row .MuiTableCell-body .MuiIconButton-label': {
                    color: 'rgb(26, 41, 54)',
                    fontWeight: '600'
                }
            },
        },
        MuiTableCell: {
            body: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#253b4d'
            },
        },
        MuiTableHead: {
            root: {
                "& .MuiTableCell-head": {
                    backgroundColor: '#929da6',
                    color: "#fff",
                    fontWeight: 'bold',
                    fontSize: '0.8rem'
                },
                "& .MuiTableCell-head:hover": {
                    backgroundColor: 'rgb(134, 146, 155)',
                    color: "rgb(245, 246, 247)",
                },
                "& .MuiTableCell-head:": {
                    backgroundColor: 'rgb(134, 146, 155)',
                    color: "rgb(245, 246, 247)",
                }
            }
        },
        MuiTableSortLabel: {
            root: {
                '&&:hover': {
                    color: 'rgb(245, 246, 247)',
                },
                '&&.MuiTableSortLabel-active': {
                    color: 'rgb(209, 216, 222)',
                }
            },
            icon: {
                fill: 'rgb(209, 216, 222)',
            }
        },
        MuiToolbar: {
            root: {
                '&& .MuiInputBase-formControl': {
                    minHeight: '42px'
                }
            }
        },
        MuiTypography: {
            body2: {
                fontSize: '0.775rem'
            },
            h5: {
                fontSize: '1.2rem'
            }
        },
        RaNotification:{
            success: {
                color: 'white',
                backgroundColor: '#00c800'
            },
            warning: {
                backgroundColor: '#ff0000',
                color: '#fff',
            },
            error: {
                backgroundColor: '#b00',
                color: '#fff'
            }
        },
        RaAppBar: {
            toolbar: {
                '& button[title^="Обновить"]': {
                    display: 'none'
                }
            }
        },
        RaAutocompleteArrayInput: {
            chip: {
                borderRadius: '10px'
            }
        },
        RaAutocompleteSuggestionList: {
            suggestionsPaper: {
                backgroundColor: '#253b4d',
                transition: 'opacity 417ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
                    'transform 278ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderRadius: '4px'
            }
        },
        RaAutocompleteInput: {
            container: {
                maxWidth: 'fit-content'
            }
        },
        RaCheckboxGroupInput: {
            label: {
                fontSize: '20pt'
            }
        },
        RaDatagrid: { //Row Details styles
            expandedPanel: {
                backgroundColor: "#f5f6f7",
                color: '#929da6'
            },
            clickableRow: {
                "&&:hover": {
                    backgroundColor: "#f5f6f7"
                },
            },
        },
        RaFilterForm: {
            form: {
                alignItems: 'flex-start'
            }
        },
        RaFilterFormInput: {
            body: {
                alignItems: 'flex-start'
            },
            hideButton: {
                padding: '0',
                margin: '8px 2px'
            }
        },
        RaLayout: {
            content: {
                backgroundColor: '#f5f6f7'
            }
        },
        RaList: {
            root: {
                marginTop: '15px'
            }
        },
        RaListToolbar: {
            toolbar: {
                '&&': {
                    minHeight: '0px'
                }
            }
        },
        RaLogin: {
            main: {
                backgroundImage: 'none',
                backgroundColor: '#253b4d',
            },
            avatar: {
                display: 'none'
            }
        },
        RaLoginForm: {
            button: {
                backgroundColor: '#1a2936',
                color: '#fff'
            }
        },
        RaMenu: {
            main: {
                marginTop: '1.5em'
            }
        },
        RaMenuItemLink: {
            root: {
                color: '#929da6',
                '&& svg': {
                    fill: '#929da6'
                }
            },
            'active': {
                color: '#f5f6f7',
                backgroundColor: '#1a2936',

                '&& svg': {
                    fill: '#f5f6f7',
                }
            }
        },
        RaSelectArrayInput: {
            chip: {
                borderRadius: '10px'
            }
        },
        RaSidebar: {
            fixed: {
                backgroundColor: '#253b4d'
            },
            root: {
                '&& .MuiListItem-button:hover': {
                    backgroundColor: 'rgba(26,41,54,1)!important',
                    color: 'rgba(146,157,166,1)!important',
                },
                '&& .RaMenuItemLink-icon:hover': {
                    fill: 'rgba(146,157,166,1)!important',
                    color: 'rgba(146,157,166,1)!important',
                },
                '&& .MuiListItem-button:active': {
                    backgroundColor: 'rgba(26,41,54,1)',
                    color: 'rgba(245,246,247,1)',
                    '&& svg': {
                        fill: 'rgba(146,157,166,1)!important',
                    }
                }
            }
        },
        RaToolbar: {
            toolbar: {
                backgroundColor: '#929da6',
                '&& .MuiButton-textPrimary:not(.Mui-disabled), .MuiButton-text:not(.Mui-disabled)': {
                    color: '#ffffff',
                    minHeight: 'inherit',
                    '&:hover': {
                        backgroundColor: 'rgb(134, 146, 155)'
                    },
                    '&:active': {
                        color: 'rgb(209, 216, 222)',
                        backgroundColor: 'rgb(134, 146, 155)',

                        '&::after': {
                            backgroundColor: 'rgb(134, 146, 155)',
                        }
                    },
                },
            }
        },
        RaTopToolbar: {
            root: {
                minHeight: '0px',
                paddingTop: '12px'
            }
        },
        RaUserMenu: {
            user: {
                '&& svg:hover': {
                    fill: 'rgba(245,246,247,1)'
                },
                '&& svg:active': {
                    fill: 'rgba(245,246,247,1)'
                }
            }
        },
        RaPaginationActions: {
            currentPageButton: {
                backgroundColor: 'rgba(0,0,0,.1)'
            }
        }
    },
});