import {FileField, FileInput, SimpleForm, useRecordContext} from "react-admin";
import {Box, Typography} from "@mui/material";
import {useStyles} from "./styles";
import * as React from "react";

export const FileUploadForm = ({basePath, accept, description, ...rest}) => {
    const styles = useStyles();
    const record = useRecordContext();

    return <SimpleForm {...rest}>
        <Box className={styles.editFileUploadContainer}>

            <Box className={styles.editFileUploadLeftPane}>
                <Typography>{description}</Typography>
                <FileInput source="attachment"
                           accept={accept}
                           label={''}
                >
                    <FileField emptyText={`${record.fileName}`} title="title" />
                </FileInput>
            </Box>
        </Box>
    </SimpleForm>;
}
