import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core/styles";

const useModalStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        marginLeft: "15px",
        marginBottom: "15px",
        height: "100%"
    },
    outlineButtons: {
        "&:hover": {
            backgroundColor: 'transparent',
            color: '#253b4d'
        }
    },
}))

export const Modal = ({open, onClose, content, contentText, title, actions}) => {
    const classes = useModalStyles()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {contentText}
                </DialogContentText>
                {content}
            </DialogContent>
            <DialogActions className={classes.wrapper}>
                {actions}
            </DialogActions>
        </Dialog>
    );
}
