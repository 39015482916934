import {Box, Typography} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import {FunctionField, RichTextField, Show, SimpleShowLayout} from "react-admin";
import {FEEDBACK_MARKS} from "../../api/constants";


export const ShowFeedback = ({...props}) => {
    return <Box>
        <PageTitle header={'Обратная связь пользователя'}/>
        <Show {...props}>
            <SimpleShowLayout>
                <FunctionField source={'name'}
                               label={'Имя'}
                               render={(record) =>
                                   <Typography variant={'body2'}>
                                       {record.name}
                                   </Typography>}/>
                <FunctionField source={'feedbackMark'}
                               label={'Оценка приложения'}
                               render={(record) =>
                                   <Typography component={'a'}>
                                       {FEEDBACK_MARKS[record.feedbackMark]}
                                   </Typography>}/>
                <RichTextField source="message" label={'Сообщение обратной связи'}/>
            </SimpleShowLayout>
        </Show>
    </Box>
}
