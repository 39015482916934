import {DeleteButton, Edit, SaveButton, Toolbar, useNotify, useRedirect} from 'react-admin';
import {ROUTE_URLS} from "../../api/constants";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import CameraForm from "./CameraForm";
import {getToolbarStyles} from "../../themes/editViewToolbarStyles";
import {FloppyIcon, TrashIcon} from "../../icons/iconsFactory";
import CancelButton from "../../components/CancelButton";

export const EditCamera = props => {

    const notify = useNotify();
    const redirect = useRedirect();

    const onFailure = (error) => {
        notify(error.message, {type: 'warning'});
    };

    const onSuccess = () => {
        redirect(`/${ROUTE_URLS.CAMERAS}`);
    };

    const EditToolbar = props => {
        const {
            onSave,
            pristine,
        } = props;

        return <Toolbar {...props} classes={getToolbarStyles()}>
            <SaveButton label="Сохранить"
                        icon={<FloppyIcon/>}
                        variant="text"
                        onSave={onSave}
                        disabled={pristine}/>
            <CancelButton/>
            <DeleteButton icon={<TrashIcon/>}
                                               mutationMode="pessimistic"
                                               confirmTitle={`Удалить камеру "${props.record.title}"`}/>
        </Toolbar>
    }


    return <Box>
        <PageTitle header={pageNaming.cameras.edit.header}
                   description={pageNaming.cameras.edit.description}
        />
        <Edit {...props}
              mutationMode="pessimistic"
              onFailure={onFailure}
              onSuccess={onSuccess}
        >
            <CameraForm toolbar={<EditToolbar />}
                        isNew={false} />
        </Edit>
    </Box>
}