import {makeStyles} from "@material-ui/core/styles";

export const usePolicyStyles = makeStyles(() => ({
    container: {
        height: '100vh',
        background: 'white',
        backgroundImage: `url(${require('../../images/cruise/background/waves.png')})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        wordWrap: 'break-word',
        paddingTop: '20vh'
    }
}))
