import * as React from "react";
import {useRedirect,} from 'react-admin';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import {RedoIcon} from "../icons/iconsFactory";

const CancelButton = ({redirectPath, ...restProps}) => {
    const redirect = useRedirect();

    const getCancelButtonStyles = makeStyles({
        cancelButton: {
            flex: '1',
            marginLeft: '10px',
            minHeight: 'inherit'
        }
    });

    return (
        <>
            <Button onClick={() => redirect(redirectPath || restProps.basePath)} style={{color: '#ffffff'}}>
                <RedoIcon/>
                <span style={{marginLeft: '8px'}}>Отмена</span>
            </Button>
            <Box className={getCancelButtonStyles().cancelButton}/>
        </>
    )
}

export default CancelButton;
