import {Box, Container, ThemeProvider,} from "@mui/material";
import {RouteInfo} from "../routeInfo/RouteInfo";
import {StaffInfo} from "../staff/StaffInfo";
import {ShipInfo} from "../shipInfo/ShipInfo";
import {Header} from "../header/Header";
import {cruiseTheme} from "./cruiseStyles";
import {useEffect, useState} from "react";
import {getTripData} from "../../../api/trip";

export const Cruise = () => {
    const [cruiseData, setCruiseData] = useState(null);

    useEffect(() => {
        getTripData()
            .then((response)=>{
                setCruiseData(response.data);
            });
    }, []);

    return <ThemeProvider theme={cruiseTheme}>
        {cruiseData && <>
            <Header imageIds={cruiseData.ship.imageIds}/>
            <Box style={{backgroundImage: 'url(./Waves.png)',}}>
                <Container style={{
                    display: "flex",
                    flexDirection: 'column',
                    gap: '1rem',
                    backgroundColor: 'transparent',
                    paddingBottom: '24px'
                }}>
                    <ShipInfo shipInfoData={cruiseData.ship}/>
                    <StaffInfo staff={cruiseData.crew}/>
                    <RouteInfo route={cruiseData.trackStops}/>
                </Container>
            </Box>
        </>}
    </ThemeProvider>
}
