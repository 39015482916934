import {buttonStyleOverrideSx, useAuthPagesStyles} from "./authPagesStyles";
import {CircularProgress} from "@material-ui/core";
import {Button} from "@mui/material";
import * as React from "react";

export const AuthButton = props => {
    const {loading, children, disabled} = props;
    const classes = useAuthPagesStyles();
    return (
        <Button variant='contained'
                {...props}
                sx={buttonStyleOverrideSx}
                disabled={disabled || loading}
                disableRipple
        >
            {loading && (
                <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                />
            )}
            {children}
        </Button>
    )
}