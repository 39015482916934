import {Feature} from "ol";
import {Point} from "ol/geom";
import {FeatureType, transformCoordinatesForPoint} from "./common.ts";
import {getPoiStyle} from "./styles";

export const makePoisFeatures = (pois, type) => {
    return pois.map(poi => {
        let commonFeatureProperties = {
            geometry: new Point(transformCoordinatesForPoint(poi.coordinate)),
            type: type,
            id: poi.id,
            title: poi.title,
            coordinate: poi.coordinate,
        }

        if(type === FeatureType.STOP) {
            commonFeatureProperties = {
                ...commonFeatureProperties,
                description: poi?.description,
                arrivalDate: poi?.arrivalDate,
                departureDate: poi?.departureDate
            }
        }
        const feature = new Feature({
            ...commonFeatureProperties
        });
        feature.setStyle(getPoiStyle(poi.title, type));
        return feature;
    })
}
