import {asGuid} from "../api/common";
import {GUID} from "../api/common.d";

export const API_PATH_PREFIX = 'biss-api';

export const API_PATH_WEBAPP = API_PATH_PREFIX + '/webapp';
export const AUTHENTICATION_SERVICE_HOST = process.env.NODE_ENV === 'development' ? `http://${process.env.REACT_APP_BACKEND_ADDRESS}:7080` : '' ;
export const BUILD_NUMBER = 4;

export const POSITION_UPDATE_FREQUENCY_MS = 3000;
