import {TrackPoint} from "../../../api/tracks.d";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {getCurrentPosUnknownStyle} from './styles';
import {transformCoordinatesForPoint} from './common';

export const makeCurrentPosFeature = (trackPoint: TrackPoint) : Feature => {

    const feature = new Feature({
        geometry: new Point(transformCoordinatesForPoint(trackPoint)),
    });
    feature.setStyle(getCurrentPosUnknownStyle());
    return feature;
}
