export enum GPSModuleType {
    DUMMY = 'DUMMY',
    HARDWARE = 'HARDWARE'
}

export type CurrentSettings = {
    currentComPort: string;
    comPortsAvailable: string[];
    gpsModuleType: GPSModuleType;
    gpsLastUpdatedAt: string;
    gpsReceiveTimedOut: boolean;
    latestLongtitude: number;
    latestLatitude: number;
}
