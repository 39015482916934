import {makeStyles} from "@material-ui/core/styles";

export const useAppBarStyles = makeStyles(() => ({
    image: {
        backgroundImage: 'url(./logo.png)',
        backgroundSize: '91px 40px',
        width: '91px',
        height: '40px'

    },
    caption: {
        flexGrow: '1',
        marginLeft: '15px'
    },
    loginButton: {
        color: '#929da6',

        '&&:hover': {
            color: 'rgba(245,246,247,1)',
        }
    }
}))