import jsonServerProvider from 'ra-data-json-server';
import httpClient from "./httpClient";
import {ROUTE_URLS} from "./constants";
import {convertFileToBase64, isFile} from "./utils";

const dataProvider = jsonServerProvider('', httpClient);

/**
 * кастомный dataProvider нужен для того, чтобы конвертировать файлы в base64
 * https://marmelab.com/react-admin/DataProviders.html#handling-file-uploads
 */
export const bissDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        return handleFileUpload(resource, params, dataProvider.update);
    },
    create: (resource, params) => {
        return handleFileUpload(resource, params, dataProvider.create);
    }
}

const handleFileUpload = (resource, params, fallbackFunction) => {
    if (!isFileUploadAction(resource)) {
        return fallbackFunction(resource, params);
    }

    const attachment = params.data.attachment;
    let metadata = {};
    if (isFile(attachment)) {
        //загружаем новый файл или меняем существующий
        metadata = {
            ...metadata,
            fileName: `${attachment.rawFile.name}`,
            sizeBytes: `${attachment.rawFile.size}`,
        };
    }
    if (params.data.id) {
        //изменяем существующую загрузку
        metadata = {
            id: params.data.id,
            ...metadata,
        }
    }
    return convertFileToBase64(attachment)
        .then(base64File => fallbackFunction(resource, {
                data: {
                    ...metadata,
                    base64File: base64File
                }
            })
        );
}

const isFileUploadAction = (resource) => {
    return [
        ROUTE_URLS.UPLOAD_IMAGE,
        ROUTE_URLS.UPLOAD_VIDEO,
        ROUTE_URLS.UPLOAD_AUDIO,
        ROUTE_URLS.UPLOAD_INSTRUCTIONS,
        ROUTE_URLS.TRACK,
        ROUTE_URLS.TRIP,
        ROUTE_URLS.UPLOAD_LOGO
    ]
        .includes(resource);
}
