import {useState} from "react";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {TextInput} from "./TextInput";

export const PasswordInput = (props) => {
    const {hasConfirm} = props;
    const [showPassword, setShowPassword] = useState(false);

    return <TextInput {...props}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      onClick={() => {
                                          setShowPassword(!showPassword);
                                          hasConfirm(showPassword);
                                      }}
                                      onMouseDown={e => e.preventDefault()}
                                  >
                                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
    />
}