const pageNaming = {
    admins: {
        list: {
            header: 'Администраторы',
            description: 'Вы можете добавлять и редактировать данные управляющего персонала ' +
                '(администраторов, которые смогут пользоваться Бэк-офисом)'
        },
        create: {
            header: 'Создание новой учетной записи',
            description: `Вы можете добавить администратора в приложение`
        },
        edit: {
            header: 'Настройки учетной записи',
            description: 'Вы можете редактировать персональные данные, заблокировать или удалить администратора'
        },
        show: {
            header: 'Личный кабинет администратора',
            description: 'Вы можете просмотреть свои персональные данные и при необходимости изменить пароль'
        }
    },
    users: {
        list: {
            header: 'Пользователи',
            description: 'Вы можете добавлять и редактировать данные туристов в круизе ' +
                '(пользователей мобильного приложения), а по окончании рейса – очищать весь список туристов'
        },
        create: {
            header: 'Создание новой учетной записи',
            description: `Вы можете добавить пользователя в приложение`
        },
        edit: {
            header: 'Настройки учетной записи',
            description: 'Вы можете редактировать персональные данные, заблокировать или удалить пользователя'
        }
    },
    images: {
        list: {
            header: 'Изображения',
            description: 'Вы можете загружать изображения, которые отображаются на странице описания круиза и' +
                ' достопримечательностей в мобильном приложении'
        },
    },
    audios: {
        list: {
            header: 'Аудиофайлы',
            description: 'Вы можете загружать аудиофайлы, которые отображаются на странице достопримечательностей ' +
                'в мобильном приложении'
        },
    },
    videos: {
        list: {
            header: 'Видеофайлы',
            description: 'Вы можете загружать видеофайлы, которые отображаются на странице достопримечательностей ' +
                'в мобильном приложении'
        },
    },
    logos: {
        list: {
            header: 'Логотипы',
            description: 'Вы можете загружать логотипы приложения'
        },
    },
    feedback: {
        list: {
            header: 'Обратная связь',
            description: 'Список полученных сообщений обратной связи от пользователей мобильного приложения'
        },
    },
    cruiseCards: {
        list: {
            header: 'Список круизных карт',
            description: ''
        },
        create: {
            header: 'Создание новой круизной карты',
            description: ''
        },
        edit: {
            header: 'Редактирование круизной карты',
            description: ''
        }
    },
    pois: {
        list: {
            header: 'Список достопримечательностей',
            description: 'Вы можете просматривать список всех достопримечательностей, ' +
                'редактировать их, добавлять новые.'
        },
        create: {
            header: 'Добавление новой достопримечательности',
            description: `Вы можете добавить достопримечательность`
        },
        edit: {
            header: 'Редактирование достопримечательности',
            description: 'Вы можете редактировать или удалить достопримечательность'
        },
    },
    cameras: {
        list: {
            header: 'Бортовые видеокамеры',
            description: 'Вы можете управлять объектами, видеотрансляция с которых отображается ' +
                'на странице «Камеры» в мобильном приложении'
        },
        create: {
            header: 'Добавление новой камеры',
            description: `Вы можете добавить камеру`
        },
        edit: {
            header: 'Редактирование камеры',
            description: 'Вы можете редактировать или удалить камеру'
        },
    },
    help: {
        list: {
            header: 'Руководство по приложению',
            description: 'Вы можете загрузить PDF-файл, который отображается на странице ' +
                '«Помощь по приложению» в мобильном приложении'
        },
    },
    trip: {
        list: {
            header: 'Рейс',
            description: 'Вы можете загрузить файл с описанием рейса и остановками'
        },
    },
    track: {
        list: {
            header: 'Маршрут',
            description: 'Вы можете загрузить файл маршрута с точками следования судна в формате JSON.'+' Размер файла не должен превышать 10 Мб'
        },
    },
    tiles: {
        create: {
            header: "Загрузка тайлов карты",
            description: "Тайлы карт – это изображения, составляющие карту в мобильном приложении.\n" +
                "Вы можете загрузить ZIP-архив с тайлами карт в формате JPEG или PNG.\n " +
                "При загрузке новых тайлов старые не удаляются"
        }
    }
}
export default pageNaming;
