import {Button, useNotify, useRefresh, useVersion} from "react-admin";
import {Box, Typography} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTilesStyles} from "./styles";
import moment from "moment/moment";
import {deleteTiles, loadTilesStatistics} from "../../api/tiles";
import {formatFileSize} from "../../api/utils";
import {Modal} from "../../components/Modal";
import {Block as BlockIcon, Check as CheckIcon, DeleteSweep as DeleteSweepIcon} from "@mui/icons-material";
import {FileUploadForm} from "../../components/fileUpload/FileUploadForm";
import {ROUTE_URLS} from "../../api/constants";

const TilesStatisticProps = {
    totalTilesCount: 0,
    totalTilesSize: 0,
    lastUpdatedAt: new Date()
}

export const Tiles = (props) => {
    const classes = useTilesStyles()
    const [tilesStatistics, setTilesStatistics] = useState(TilesStatisticProps);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const version = useVersion()
    const notify = useNotify()
    const refresh = useRefresh()

    useEffect(() => {
        loadTilesStatistics()
            .then((request) => {
                setTilesStatistics(request.data);
            });
    }, [version]);

    const handleDeleteAll = () => {
        deleteTiles()
            .then(() => {
                notify('Все тайлы карт удалены');
                refresh();
            })
            .catch(error => {
                notify('Ошибка при удалении тайлов карт: ' + error);
                refresh();
            });
    }

    const RenderStats = () => {
        return (
            <Box className={classes.tilesStatisticsContainer}>
                <Typography>
                    <b>Общее количество тайлов карт в базе данных:</b> {tilesStatistics?.totalTilesCount}
                </Typography>
                <Typography>
                    <b>Общий размер тайлов карт:</b> {formatFileSize(tilesStatistics?.totalTilesSize)}
                </Typography>
                <Typography>
                    <b>Дата последней загрузки тайлов
                        карт:</b> {
                    tilesStatistics.lastUpdatedAt ? moment(tilesStatistics.lastUpdatedAt).format('DD.MM.yyyy HH:mm') : '-'
                }
                </Typography>
            </Box>);
    }

    const RenderDeleteTilesDialog = () => {
        return <Box>
            <Button onClick={() => setOpenDeleteDialog(true)}
                    label={'Удалить все тайлы карт из базы данных'}
            >
                <DeleteSweepIcon/>
            </Button>
            <Modal open={openDeleteDialog}
                   onClose={() => {
                       setOpenDeleteDialog(false)
                   }}
                   title={'Вы уверены, что хотите удалить все тайлы карт?'}
                   actions={<Box>
                       <Button onClick={() => {
                           handleDeleteAll();
                           setOpenDeleteDialog(false);
                       }}
                               label={'Подтвердить'}
                       >
                           <CheckIcon/>
                       </Button>
                       <Button onClick={() => setOpenDeleteDialog(false)}
                               label={'Отменить'}
                       >
                           <BlockIcon/>
                       </Button>
                   </Box>}
            />
        </Box>
    }

    return <Box>
        <PageTitle header={pageNaming.tiles.create.header}
                   description={pageNaming.tiles.create.description}/>
        <FileUploadForm actions={<RenderDeleteTilesDialog/>}
                        accept={props.mimeType}
                        sendWithBase64={false}
                        resource={ROUTE_URLS.TILES}
                        label={''}
        >
            {tilesStatistics && <RenderStats/>}
        </FileUploadForm>
    </Box>
}
