import {Create, useNotify, useRedirect} from 'react-admin';
import {FileUploadForm,} from "./FileUploadForm";
import * as React from "react";
import {CreateToolbar} from "../../components/CreateToolbar";
import {MIME_TYPES} from "../../api/constants";

export const CreateBlob = props => {
    const {accept, redirectOnSuccess, description} = props;
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        let successMessage;

        switch (accept) {
            case MIME_TYPES.VIDEO:
                successMessage = 'Видео успешно загружено';
                break;
            case MIME_TYPES.IMAGE:
                successMessage = 'Изображение успешно загружено';
                break;
            case MIME_TYPES.AUDIO:
                successMessage = 'Аудио файл успешно загружен';
                break;
            case MIME_TYPES.PDF:
                successMessage = 'Pdf документ успешно загружен';
                break;
            case MIME_TYPES.JSON:
                successMessage = 'JSON файл успешно загружен';
                break;
            default:
                successMessage = 'Файл успешно загружен';
        }
        notify(successMessage);
        redirect(`/${redirectOnSuccess}`);
    }

    return <Create {...props}
                   mutationMode="pessimistic"
                   onSuccess={onSuccess}
    >
        <FileUploadForm accept={accept} toolbar={<CreateToolbar/>} description={description} isNew={true}/>
    </Create>;
}
