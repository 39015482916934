import {makeStyles} from "@material-ui/core/styles";

const container = {
    background: '#fafafa',
    height: '67px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 200ms ease-out',
    '&:hover': {
        filter: 'brightness(95%)'
    }
}

export const useFileInputStyles = makeStyles({
    container: {
        ...container,
    },
    containerIsDragActive: {
        ...container,
        filter: 'brightness(90%)'

    },
    labelText: {
        color: '#8f9ea8',
        fontSize: '12px',
        cursor: 'default'
    },
    placeholder: {
        color: 'black',
        textAlign: 'center',
        lineHeight: 1,
        fontWeight: 400
    },
    acceptedFilesContainer: {
        padding: '1em 0',
        display: "flex",
        flexDirection: 'column',
        gap: '15px',
    },
    acceptedFile: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
    },
    deleteFileIcon: {
        width: 24,
        height: 24,
    },
    deleteFileIconBorder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48,
        height: 48,
        borderRadius: '50%',
        backgroundColor: 'rgba(0,0,0,0)',
        cursor: 'pointer',
        color: '#F00',
        transition: 'all 500ms ease',
        '&:hover': {
            transition: 'all 500ms ease',
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
        '&:active': {
            transition: 'all 500ms ease',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    }
})
