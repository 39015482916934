import {Box, Typography} from "@mui/material";
import {Link} from "@material-ui/core";
import {loadCurrentApplicationDocument} from "../../api/applicationDocument";
import {downloadFile, parseFileNameFromContentDisposition} from "../../api/utils";
import {useTermStyles} from "./styles";
import {APPLICATION_DOCUMENT_TYPES} from "../../api/constants";

export const TermOfUse = () => {
    const classes = useTermStyles()
    const onDownloadClick = () => {
        loadCurrentApplicationDocument(APPLICATION_DOCUMENT_TYPES.TERMS_OF_USE)
            .then((request) => {
                const blob = URL.createObjectURL(request.data);
                const fileName = parseFileNameFromContentDisposition(request.headers['content-disposition']);
                downloadFile(blob, fileName);
            });
    }
    return <Box className={classes.container}>
        <Typography variant={'h6'} align={"center"} >
            <Link underline="always" onClick={onDownloadClick} >
                Скачать пользовательское соглашение (PDF)
            </Link>
        </Typography>
    </Box>
}
