import {HTTP_METHOD, ROUTE_URLS} from "./constants";
import {fetch} from "./httpClient";

export const uploadTiles = (formData) => {
    const url = `/${ROUTE_URLS.TILES}`;
    return fetch(url, {
        method: HTTP_METHOD.POST,
        data: formData
    });
}

export const deleteTiles = () => {
    const url = `/${ROUTE_URLS.TILES}`;
    return fetch(url, {
        method: HTTP_METHOD.DELETE
    });
}

export const loadTilesStatistics = () => {
    const url = `/${ROUTE_URLS.TILES_STATS}`;
    return fetch(url, {
        method: HTTP_METHOD.GET
    });
}
