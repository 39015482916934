import {Box, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {Card} from "../../../components/Card";
import {stepLabelStyles, useRouteInfoStyles} from "./routeInfoStyles";
import {Route} from "./Route";
import {useEffect, useRef, useState} from "react";

export const RouteInfo = ({route}) => {
    const [lineHeight, setLineHeight] = useState(100);
    const stepperRef = useRef(null)

    const stepLabelClasses = stepLabelStyles();
    const classes = useRouteInfoStyles({lineHeight});

    useEffect(() => {
        const steps = Object.values(stepperRef.current.children)
        setLineHeight(steps[steps.length - 1].offsetTop - steps[0].offsetTop)
    }, [])

    return <Card>
        <Box className={classes.titleBox}>
            <Typography variant="span" className={classes.title}>Маршрут</Typography>
        </Box>
        <Box className={classes.contentBox}>
            <Box className={classes.line}></Box>
            <Stepper activeStep={route.length}
                     orientation="vertical"
                     variant={'outlined'}
                     connector={<></>}
                     ref={stepperRef}
            >
                {
                    route.map((stop, index) => (
                        <Step key={stop.id} className={classes.stepContainer}>
                            <StepLabel
                                StepIconComponent={() => <svg height="100%" width="100%" className={classes.stepIcon}>
                                    <circle r="7.3" fill="#2161E3" cx="12.5" cy="12.5"></circle>
                                </svg>}
                                classes={stepLabelClasses}
                            >
                                <Route {...{
                                    ...stop,
                                    arrivalDate: index === 0 ? null: stop.arrivalDate,
                                    departureDate: index === (route.length - 1) ? null : stop.departureDate
                                }}/>

                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </Box>
    </Card>
}
