import {TrackPoint} from "../../../api/tracks.d";
import MultiLineString from "ol/geom/MultiLineString";
import * as olProj from "ol/proj";
import {Feature} from "ol";
import {trackStyle} from "./styles";

export const makeTrackFeature = (points: TrackPoint[]) : Feature => {
    const trackLine = [];
    for (let i = 0; i < (points.length - 1); i++) {
        let start = olProj.fromLonLat([points[i].longitude, points[i].latitude]);
        let end = olProj.fromLonLat([points[i + 1].longitude, points[i + 1].latitude]);
        let line = [start, end];
        trackLine.push(line);
    }
    const trackFeature = new Feature({
        geometry: new MultiLineString(trackLine)
    });
    trackFeature.setStyle(trackStyle);
    return trackFeature;
}
