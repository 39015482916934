import React from "react";
import {StopPoint} from '../../../../api/tracks.d';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Dialog, DialogContent, DialogContentText, IconButton, Typography} from "@mui/material";
import {Close as CloseIcon} from '@mui/icons-material';
import {getFormatDateTime} from "../../../../api/utils";

type StopDescriptionPopupProps = {
    selectedStopFeature: StopPoint,
    open: boolean,
    onClose: () => void
}

export const StopDescriptionPopup = ({open, selectedStopFeature, onClose}: StopDescriptionPopupProps) => {

    return <Dialog open={open}>
        <DialogTitle>
            {selectedStopFeature?.title}
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {selectedStopFeature?.description ?? <Typography color={'black'}>
                    {selectedStopFeature?.description}
                </Typography>}
                {(selectedStopFeature?.arrivalDate || selectedStopFeature?.departureDate) && <hr/>}
                {selectedStopFeature?.arrivalDate && <Typography>
                    Прибытие: {getFormatDateTime(selectedStopFeature?.arrivalDate)}
                </Typography>}
                {selectedStopFeature?.departureDate && <Typography>
                    Отправление: {getFormatDateTime(selectedStopFeature?.departureDate)}
                </Typography>}
                {(selectedStopFeature?.coordinate?.latitude || selectedStopFeature?.coordinate?.longitude) && <hr/>}
                {selectedStopFeature?.coordinate?.latitude && <Typography>
                    Широта: {selectedStopFeature?.coordinate?.latitude}
                </Typography>}
                {selectedStopFeature?.coordinate?.longitude && <Typography>
                    Долгота: {selectedStopFeature?.coordinate?.longitude}
                </Typography>}
            </DialogContentText>
        </DialogContent>
    </Dialog>
}
