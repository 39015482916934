import {Datagrid, List, TextField} from "react-admin";
import * as React from "react";
import {Box} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import EmptyPage from "../../components/EmptyPage";

export const ListCamera = ({...props}) => {
    return <Box>
        <PageTitle header={pageNaming.cameras.list.header} description={pageNaming.cameras.list.description}/>
        <List {...props}
              exporter={false}
              empty={<EmptyPage header={'Нет бортовых видеокамер'}/>}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source={'title'} label={'Заголовок'}/>
                <TextField source={'url'} label={'URL'}/>
                <TextField source={'description'} label={'Описание'}/>
            </Datagrid>
        </List>
    </Box>
}
