import {Card} from "../../../components/Card";
import {Box, Typography} from "@mui/material";
import {getImageById, UserImageNotFound} from "../imagesFactory";
import {useStaffInfoStyles} from "./staffInfoStyles";

const PersonBox = ({position, name, image}) => {
    const classes = useStaffInfoStyles()

    return <Box className={classes.personBox}>
        <Box className={classes.imageBox}>
            <img src={image}
                 onError={(e) => {
                     e.target.onerror = null;
                     e.target.src = UserImageNotFound;
                 }}
                 className={classes.image}
            />
        </Box>
        <Box>
            <Typography>
                {position}
            </Typography>
            <Typography>
                <b>{name}</b>
            </Typography>
        </Box>
    </Box>
}

export const StaffInfo = ({staff}) => {
    const classes = useStaffInfoStyles()
    return <Card>
        <Box className={classes.container}>
            {staff.map((person, index) =>
                <PersonBox name={person.fullName}
                           position={person.position}
                           image={getImageById(person.imageId)}
                           key={index}
                />)}
        </Box>
    </Card>
}
