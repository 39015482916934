import {makeStyles} from "@material-ui/core";

export const logoutStyles = makeStyles(() => ({
    logoutButton: {
        color: '#929da6',
        "&:active": {
            color: '#f5f6f7',
            backgroundColor: '#1a2936'
        }
    }
}))