import {Card, List, ListItem, ListItemText} from "@material-ui/core";
import {PageTitle} from "../../components/PageTitle";
import {useStyles} from "./styles";
import {useEffect, useState} from "react";
import {getCurrentSettings} from "../../api/settings";
import {CurrentSettings, GPSModuleType} from '../../api/settings.d.ts';
import {Divider, Typography} from "@mui/material";
import {useVersion} from "react-admin";

export const Settings = () => {
    const classes = useStyles();
    const version = useVersion()
    const [currentSettings, setCurrentSettings] = useState(null);

    useEffect(() => {
        getCurrentSettings()
            .then((response)=>{
                setCurrentSettings(response.data);
            });
    }, [version]);

    const formatGPSType = (gpsType: GPSModuleType) => {
        return gpsType === GPSModuleType.HARDWARE ? "GPS Антенна": "Эмулятор";
    }

    const gpsStatus = (currentSettings: CurrentSettings) => {
        if(currentSettings.gpsReceiveTimedOut){
            return <ListItem key={4}>
                <ListItemText primary={`Ошибка получения координат от приемника. Приемник не передал данные`}/>
            </ListItem>
        }else{
            return <ListItem key={5}>
                <ListItemText primary={`Дата последнего получения координат: ${currentSettings.gpsLastUpdatedAt}`}/>
            </ListItem>
        }
    }

    return (
        <Card>
            <PageTitle header="Настройки" description="Актуальные настройки приложения и GPS-приемника" />
            {currentSettings &&
                <>
                    <Typography variant="h6" className={classes.subheading} >Настройки GPS приемника</Typography>

                    <List className={classes.stack}>
                        <ListItem key={1}>
                            <ListItemText primary={`Тип GPS приемника: ${formatGPSType(currentSettings.gpsModuleType)}`}/>
                        </ListItem>
                        {currentSettings.gpsModuleType === GPSModuleType.HARDWARE &&
                            <>
                                <ListItem key={2}>
                                    <ListItemText primary={`Используемый COM порт: ${currentSettings.currentComPort}`}/>
                                </ListItem>
                                <ListItem key={3}>
                                    <ListItemText primary={`Список доступных COM портов: ${currentSettings.comPortsAvailable}`}/>
                                </ListItem>
                                {gpsStatus(currentSettings)}
                                {!!(currentSettings.latestLatitude && currentSettings.latestLongtitude) &&
                                    <ListItem key={6}>
                                        <ListItemText primary={`Последние полученные координаты: Lat=${currentSettings.latestLatitude}, Long=${currentSettings.latestLongtitude}`}/>
                                    </ListItem>
                                }
                            </>
                        }
                    </List>
                    <Divider/>
                </>
            }
        </Card>
    );
}
