import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Container, Fab, Fade, useScrollTrigger} from "@mui/material";
import PropTypes from "prop-types";
import {useHeaderStyles} from "./headerStyles";
import {getImageById} from '../imagesFactory'
import {KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";
import {Slide} from "react-slideshow-image";
import {Typography} from "@material-ui/core";
import {fetch} from "../../../api/httpClient";
import {ROUTE_URLS} from "../../../api/constants";

/*
* функционал шапки с возвратом в верх страницы
* https://mui.com/material-ui/react-app-bar/#back-to-top
* */

const ScrollTop = (props) => {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return <Fade in={trigger}>
        <Box
            onClick={handleClick}
            role="presentation"
            sx={{position: 'fixed', bottom: 16, right: 16}}
        >
            {children}
        </Box>
    </Fade>
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export const Header = (props) => {
    const classes = useHeaderStyles()
    const [imageIds, setImageIds] = useState([])
    const [logo, setLogo] = useState('')

    useEffect(() => {
        fetch(`/${ROUTE_URLS.GET_LATEST_LOGO}`)
            .then(response => {
                setLogo(response.data.base64File)
            })
        if (props.imageIds.length > 10) {
            console.error('Количество изображений превышает допустимое (10 шт)')
            setImageIds(props.imageIds.slice(0, 10))
        } else {
            setImageIds(props.imageIds)
        }
    }, []);

    return <React.Fragment>
        {/*// якорь верха страницы*/}
        <Box id="back-to-top-anchor"/>
        <Container className={classes.container}>
            <Box className={classes.logoAndTitle}>
                    <img src={logo} alt="Logo" className={classes.logo}/>
                    <Typography className={classes.title}>
                        КруБИСС
                    </Typography>
            </Box>
        </Container>
        <Box className={classes.sliderContainer}>
            <Slide indicators={true}
                   transitionDuration={500}
                   autoplay={false}
            >
                {
                    imageIds.map((each, index) => <Box key={index} className={classes.imageContainer}>
                        <img style={{width: "100%"}} src={getImageById(each)} alt={each}/>
                    </Box>)
                }
            </Slide>
        </Box>
        <ScrollTop {...props}>
            <Fab aria-label="scroll back to top">
                <KeyboardArrowUpIcon/>
            </Fab>
        </ScrollTop>
    </React.Fragment>
}
