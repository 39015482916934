import {makeStyles} from "@material-ui/core/styles";

export const useErrorStyles = makeStyles({
    errorMessage: {
        position: 'absolute',
        bottom: '0.1vh',
        left: 0,
        margin: '0 auto',
        background: '#DADADA',
        color: '#C00',
        right: 0,
        padding: '0.1vw',
        border: '1px solid #AAA',
        width: '60vw',
        textAlign: 'center'
    }
});
