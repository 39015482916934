import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles({
    descriptionText: {
        paddingLeft: '5%',
        fontSize: '0.7em'
    },
    stack: {
        paddingLeft: '5%',
        width: '100%',
    },
    subheading: {
        paddingLeft: '5%',
    }
})
