import {makeStyles} from "@material-ui/core/styles";

const userInputWidth = {
    width: "300px"
}

export const useFormStyles = makeStyles(theme => ({
            content: {
                display: 'flex',
                flexDirection: 'row',
                padding: theme.spacing(3),
                width: '96%',
                flexWrap: 'wrap',
                height: "max-content",
            },
            container: {
                display: "flex",
                margin: '0',
                justifyContent: "start",
                gap: "40px",
                width: "100%"
            },
            columns: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                "&>:nth-child(1)": {
                    width: "30%"
                }
            },
            userName: {
                ...userInputWidth
            },
            userLogin: {
                ...userInputWidth
            },
            userPassword: {
                ...userInputWidth
            },
            userDashboards: {
                ...userInputWidth
            },
            buttonBox: {
                display: 'flex',
                flexFlow: 'column',
                gap: "10px",
                paddingTop: "1%"
            },
            buttons: {
                display: "flex",
                justifyContent: "start"
            },
            pageCaption: {
                width: '100%',
                textAlign: "center",
                fontSize: '20pt',
                fontWeight: "bold"
            }
        }
    )
)